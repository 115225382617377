
















import axios from 'axios';
import Component from 'vue-class-component';
import MarkdownViewer from './Library/Markdown/MarkdownViewer.vue';
import Vue from 'vue';

@Component({
  components: { MarkdownViewer }
})
export default class Changelog extends Vue {
  changelogSource: string = '';
  version: string = process.env.VUE_APP_VERSION;
  feLongVersion: string = process.env.VUE_APP_REVISION;
  backendVersion = "loading...";
  backendVersionLong = "loading...";

  created() {
    axios.get('/CHANGELOG.md').then(res => {
      this.changelogSource = res.data;
    });

    axios.get(process.env.VUE_APP_ROOT_API + '/currentVersion').then(response => {
      if (response.data.success) {
        this.backendVersion = response.data.data.version;
        this.backendVersionLong = response.data.data.version_long;
      }
    });
  }
}
