























































import Component from 'vue-class-component';
import GroupApi from '../../../library/api/group/Group';
import Vue from 'vue';

export type ActionMode = 'ADD' | 'EDIT';

@Component({
  inject: ['theme']
})
export default class AddEditCategory extends Vue {
  action: ActionMode = 'ADD';

  groupApi!: GroupApi;
  groupId!: number;
  dialog: boolean = false;
  saving: boolean = false;

  category: any = {};

  created() {
    this.groupApi = new GroupApi();
  }

  get formTitle(): string {
    if (this.action === 'ADD') {
      return 'Add new category';
    } else {
      return 'Edit category';
    }
  }

  /**
   * Start adding or editing category
   *
   * @param groupId
   * @param action either "ADD" or "EDIT"
   * @param category the category object, if action = EDIT
   */
  initialize(groupId: number, action: ActionMode, category: any = null): void {
    this.action = action;
    this.saving = false;
    this.dialog = true;

    this.groupId = groupId;

    // either use category to edit or use empty category object
    if (category !== null) {
      this.category = category;
    } else {
      this.category = {
        name: '',
        description: '',
        color: '#666666',
      }
    }
  }

  /** Save the category */
  onSave(): void {
    this.saving = true;

    const categoryData = {
      name: this.category.name,
      description: this.category.description,
      color: this.category.color,
    };

    // add or edit the category
    switch (this.action) {
      case 'ADD':
        this.groupApi.addGroupCategory(this.groupId, categoryData).then((res) => {
          this.whenSaveFinished(res.isSuccess());
        });
        break;
      case 'EDIT':
        this.groupApi.editGroupCategory(this.groupId, this.category.id, categoryData).then((res) => {
          this.whenSaveFinished(res.isSuccess());
        });
        break;
    }
  }

  /** Handler to be called after the save process is finished. */
  whenSaveFinished(success: boolean): void {
    this.saving = false;
    this.dialog = false;
    this.$emit('categories-changed');

    let message = '';
    if (success) {
      message = 'Category successfully saved!'
    } else {
      message = 'Category could not be saved!'
    }

    this.$store.dispatch('loadGroupDetails', this.groupId);
    this.$store.commit('addGlobalMessage', {
      type: success ? 'success' : 'error',
      message: message,
      timeout: 5000,
      persistent: false
    });
  }
}
