











































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FilterCollection from '../../../library/filter/FilterCollection';
import GroupStatisticsApi from '@/library/api/group/Statistics';
import { generateRandomRequestId } from '@/store';

@Component
export default class DashboardBalanceWidget extends Vue {
  @Prop({ type: FilterCollection, required: true }) filter!: FilterCollection;
  @Prop({ type: Number, required: true }) groupId!: number;
  statisticsApi: GroupStatisticsApi = new GroupStatisticsApi();
  lastRequestId: string = '';
  tableHeaders = [
    { text: 'User', value: 'name' },
    { text: '&Sigma;', value: 'total_spent_amount' },
    { text: 'Balance', value: 'group_balance' },
    { text: '#', value: 'total_count' },
    { text: 'Average', value: 'avg_spent_amount' },
    { text: 'Min', value: 'min_spent_amount' },
    { text: 'Max', value: 'max_spent_amount' },
  ];
  columnMinWidths = {
    name: 0,
    total_spent_amount: 0,
    group_balance: 95,
    total_count: 60,
    avg_spent_amount: 0,
    min_spent_amount: 0,
    max_spent_amount: 75,
  };
  balanceStats: any = null;

  update() {
    this.balanceStats = null;
    const requestId = this.lastRequestId = generateRandomRequestId();
    this.statisticsApi.getGroupBalance(this.groupId, this.filter).then(response => {
      if (
        requestId !== this.lastRequestId ||
        !response.isSuccess()
      ) {
        return;
      }

      this.balanceStats = {
        userStats: Object.values(response.data.user_stats),
        groupTotalStats: response.data.total_stats,
      }
    });
  }
}
