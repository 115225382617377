



























































import Component from 'vue-class-component';
import GroupApi from '@/library/api/group/Group';
import Vue from 'vue';

@Component({
  inject: ['theme']
})
export default class EditUser extends Vue {
  groupApi: GroupApi = new GroupApi();
  groupId!: number;

  dialog: boolean = false;
  loading: boolean = true;
  saving: boolean = false;

  user = {
    user_id: 0,
    name: "",
    email: "",
  };
  userGroupPermissions = {
    manageCategories: false,
    inviteUsers: false
  };

  /** Details (title and description) for each possible permission */
  get permissionInfo() {
    return {
      manageCategories: {
        title: 'Category Management',
        description: this.user.name + ' can manage (create, edit & delete) the categories of this group.'
      },
      inviteUsers: {
        title: 'User Invitations',
        description: this.user.name + ' can invite new users to this group.'
      },
      removeUsers: {
        title: 'User Removals',
        description: this.user.name + ' can remove users from this group.'
      },
      manageTransactions: {
        title: 'Transaction Management',
        description: this.user.name + ' can manage (edit & delete) transactions of other users.'
      },
      manageUserPermissions: {
        title: 'User Permission Management',
        description: this.user.name + ' can manage the permissions of other users in this group.'
      }
    }
  }

  /** Initialize the user-(permission)-edit form */
  startEditUser(userItem: any, groupId: number): void {
    this.loading = true;
    this.saving = false;
    this.dialog = true;

    this.groupId = groupId;
    this.user = userItem;

    this.groupApi.getEditableUserPermissionsForUser(this.groupId, this.user.user_id).then((res) => {
      if (res.isSuccess()) {
        this.userGroupPermissions = res.data.group_permissions;
        this.loading = false;
      } else {
        this.$store.commit('addGlobalMessage', {
          type: 'error',
          message: res.messageCode,
          timeout: 5000,
          persistent: false
        });
        this.dialog = false;
      }
    });
  }

  /** Save the permissions for the user */
  onSave(): void {
    this.saving = true;

    this.groupApi.setEditableUserPermissionsForUser(
        this.groupId, this.user.user_id, this.userGroupPermissions
    ).then((response) => {
      if (response.isSuccess()) {
        this.saving = false;
        this.$store.commit('addGlobalMessage', {
          type: 'success',
          message: 'Permissions saved for ' + this.user.name,
          timeout: 5000,
          persistent: false
        });
      }
    })
  }
}
