import Vue from 'vue'
import AuthGuard from '@/router/auth-guard';
import RedirectIfAuthenticatedGuard from '@/router/redirect-if-authenticated-guard';
import Router from 'vue-router'

//---------------------------
// Component Imports
//---------------------------
import Changelog from '@/components/Changelog.vue';
import NewGroup from '@/components/Group/NewGroup/NewGroup.vue';
import Group from '@/components/Group/Group.vue';
import GroupDashboard from '@/components/Group/Dashboard.vue';
import GroupSettings from '@/components/Group/Settings.vue';
import GroupTransactions from '@/components/Group/Transactions.vue';
import Login from '@/components/Login.vue';
import Payment from '@/components/Transaction/Payment.vue';
import PersonalDashboard from '@/components/PersonalDashboard.vue';
import Profile from '@/components/Profile.vue'
import Register from '@/components/Register.vue'

//---------------------------
// Route Names
//---------------------------
export const ROUTE_GROUP_DASHBOARD = 'group-dashboard';
export const ROUTE_LOGIN = 'Login';
export const ROUTE_REGISTER = 'Register';

//---------------------------
// Router Setup
//---------------------------
Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: '/changelog',
      alias: '/whats-new',
      name: 'Changelog',
      component: Changelog
    },
    {
      path: '/',
      alias: '/dashboard',
      name: 'PersonalDashboard',
      component: PersonalDashboard
    },
    {
      path: '/group/new',
      name: 'CreateGroup',
      component: NewGroup
    },
    {
      path: '/group',
      name: 'Group',
      component: Group,
      children: [
        {
          path: 'dashboard',
          name: ROUTE_GROUP_DASHBOARD,
          component: GroupDashboard
        },
        {
          path: 'settings',
          name: 'group-setting',
          component: GroupSettings
        },
        {
          path: 'transactions',
          name: 'group-transactions',
          component: GroupTransactions
        }
      ]
    },
    {
      path: '/login',
      name: ROUTE_LOGIN,
      component: Login,
      beforeEnter: RedirectIfAuthenticatedGuard
    },
    {
      path: '/payment/:paymentId',
      props: true,
      name: 'Payment',
      component: Payment
    },
    {
      path: '/profile',
      name: 'Profile-Settings',
      component: Profile
    },
    {
      path: '/register',
      name: ROUTE_REGISTER,
      component: Register
    }
  ],
  mode: 'history'
});

router.beforeEach(AuthGuard);

export default router;