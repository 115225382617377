







































import MarkdownViewer from '../Markdown/MarkdownViewer.vue';
import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";

@Component({
  components: { MarkdownViewer }
})
export default class MarkdownEditor extends Vue {
  @Prop({ type: String, default: 'Input...' }) readonly placeholder!: string;
  @Prop({ type: String, default: 'Editor' }) readonly title!: string;
  @Prop({ type: String, default: null }) readonly value!: string|null;
}
