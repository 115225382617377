import ApiResponse from './ApiResponse';
import axios, { AxiosRequestConfig, Method } from 'axios';

export const DELETE = 'DELETE';
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';

const REQUEST_METHODS = [ DELETE, GET, POST, PUT ];

export default class ApiRequest {
    requestConfig: AxiosRequestConfig = { };
    handleGlobalExceptions: boolean = true;

    constructor(method: Method) {
        if (! REQUEST_METHODS.includes(method)) {
            return;
        }
        
        this.requestConfig.method = method;
        this.requestConfig.withCredentials = true;
        
        return this;
    }

    setUrl(url: string): ApiRequest {
        this.requestConfig.url = url;
        return this;
    }

    setData(data: any): ApiRequest {
        this.requestConfig.data = data;
        return this;
    }

    setQueryParameters(params: any): ApiRequest {
        this.requestConfig.params = params;
        return this;
    }

    withoutGlobalExceptionHandling(): ApiRequest {
        this.handleGlobalExceptions = false;
        return this;
    }
    
    async execute(): Promise<ApiResponse> {
        return new Promise((resolve, reject) => {
            axios.request(this.requestConfig).then((response) => {
                resolve(new ApiResponse(response));
            }).catch((error) => {
                if (! this.handleGlobalExceptions) {
                    reject(error);
                    return;
                }
                
                /* Global error Handling */
                // Handle unauthorized access -> reload to trigger rememberMe if available / go to login if not
                if (error.response.status === 401 && error.response.data.message_code === 'UNAUTHORIZED') {
                    document.location.reload();
                }

                /* Non-global errors/ non-handleable errors have to be handled by api sub-classes. */
                reject(error);
            });
        });
    }
}