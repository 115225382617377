import Api from './Api';
import ApiRequest from './http/ApiRequest';
import ApiResponse from './http/ApiResponse';

export default class PaymentApi extends Api {
    /**
     * Get specific Payment by ID from API.
     *
     * @param paymentId Id of the required payment.
     * @returns {Promise<ApiResponse>}
     */
    async getPayment(paymentId: Number): Promise<ApiResponse> {
        return new ApiRequest('GET')
            .setUrl(Api.buildApiUrl(`/payment/${paymentId}`))
            .execute();
    }

    /**
     * Create new payment.
     * 
     * @param {Object} paymentObject
     * @returns {Promise<ApiResponse>}
     */
    async createPayment(paymentObject: any): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl(`/payment/create`))
            .setData(paymentObject)
            .execute();
    }

    /**
     * Edit an already existing payment.
     *
     * @param paymentId     ID of the payment.
     * @param paymentObject New parameters of the payment
     * @returns {Promise<ApiResponse>}
     */
    async editPayment(paymentId: Number, paymentObject: any): Promise<ApiResponse> {
        return new ApiRequest('PUT')
            .setUrl(Api.buildApiUrl(`/payment/${paymentId}`))
            .setData(paymentObject)
            .execute();
    }

    /**
     * Delete specific payment by ID.
     *
     * @param paymentId ID of the payment to delete.
     * @returns {Promise<ApiResponse>}
     */
    async deletePayment(paymentId: Number): Promise<ApiResponse> {
        return new ApiRequest('DELETE')
            .setUrl(Api.buildApiUrl(`/payment/${paymentId}`))
            .execute();
    }
}
