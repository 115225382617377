import { JsonFilter } from '../FilterCollection';
import FilterModel, { FilterType } from '../FilterModel';

export interface JsonUserFilter extends JsonFilter {
  users: number[]
}

export default class UserFilter implements FilterModel<JsonUserFilter> {
  filterType: FilterType = 'User';

  private _users: Array<number> = [];

  get users(): Array<number> {
    return this._users;
  }

  set users(users: Array<number>) {
    this._users = users;
  }

  isEmpty(): boolean {
    return this._users.length === 0;
  }

  toUriObject(): Object {
    return { users: this.users }
  }

  toJsonFilter(): JsonUserFilter {
    return {
      type: this.filterType.toString(),
      users: this._users
    }
  }

  loadFromJsonFilter(filter: JsonUserFilter): void {
    this.users = filter.users;
  }
}