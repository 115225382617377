import axios from "axios";
import ApiRequest from './http/ApiRequest';

export default class Api {
    static tokenRefreshTimeout: Number;
    static token: string;

    static get apiUrl() {
        return process.env.VUE_APP_ROOT_API;
    }

    /**
     * Prepare (i.e. add default header) Axios headers which includes e.g.
     * the authorization header and X-Requested-With header.
     *
     * @param tokenData
     */
    static bootstrapApi(tokenData: any): void {
        Api.token = tokenData.access_token;

        // set axios default headers
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenData.access_token;
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        // set timeout to refresh api/ jwt token
        Api.tokenRefreshTimeout = setTimeout(
            Api.refreshApiToken,
            (tokenData.expires_in - 20) * 1000
        );
    }

    buildApiUrlWithApiToken(path: string): string {
        return Api.buildApiUrl(path) + '?token=' + Api.token;
    }

    static buildApiUrl(path: string): string {
        const fullPath = Api.apiUrl + path;
        // remove double slashes from url
        return fullPath.replace(/([^:]\/)\/+/g, "$1");
    }

    /** Try to obtain refreshed jwt token from api and re-bootstrap the api-js classes. */
    static refreshApiToken(): void {
        new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/uac/login/refresh'))
            .withoutGlobalExceptionHandling()
            .execute()
            .then(response => {
                Api.bootstrapApi(response.data);
            })
            .catch(error => {
                location.reload();
            });
    }
}