var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"height":"100","contain":"","src":"/img/icons/msapplication-icon-144x144.png"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Accountrol Registration")])],1),_c('v-progress-linear',{attrs:{"color":"secondary","active":_vm.registrationInProcess,"indeterminate":""}}),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.doRegistration($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_vm._l((_vm.errors),function(error){return _c('v-alert',{key:error,attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm._f("transError")(error))+" ")])}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-email","label":"Username","type":"text","rules":[_vm.rules.required]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-email","label":"E-Mail","type":"text","rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","label":"Password","type":"password","rules":[
                _vm.rules.required,
                function (v) { return _vm.rules.minLength(v, 10) },
                function (v) { return _vm.rules.securePassword(v) }
              ]},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.criteriaInfoDialog = true}}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","label":"Password Confirmation","type":"password","rules":[
                _vm.rules.required,
                function (v) { return _vm.rules.equals(v, _vm.password, 'Passwords have to match!') }
              ]},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})],2),_c('v-card-actions',[_c('router-link',{staticClass:"router-link",attrs:{"to":"/login"}},[_vm._v(" Already registered? Login! ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":! _vm.valid,"type":"submit","color":"primary"}},[_vm._v(" Register ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.criteriaInfoDialog),callback:function ($$v) {_vm.criteriaInfoDialog=$$v},expression:"criteriaInfoDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Password Criteria "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.criteriaInfoDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('ul',[_c('li',[_vm._v("At least one lowercase letter")]),_c('li',[_vm._v("At least one upercase letter")]),_c('li',[_vm._v("At least one number")]),_c('li',[_vm._v("At least one special character")]),_c('li',[_vm._v("At least 10 characters")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }