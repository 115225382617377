









































import RULES from '@/library/validation/rules';
import GroupApi from '@/library/api/group/Group';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CreateNewGroup extends Vue {
  name: string|null = null;
  description: string|null = null;

  valid: boolean = false;
  rules = RULES;

  saving: boolean = false;
  error: any = null;

  onCreateGroup(): void {
    if (! this.name || ! this.description) return;

    this.saving = true;
    new GroupApi().createNewGroup(this.name, this.description).then(response => {
      this.saving = false;
      if (! response.isSuccess()) {
        this.error = response.messageCode;
      } else {
        this.error = null;
        this.$store.dispatch('loadUserGroups');
        this.$store.commit('addGlobalMessage', {
          type: 'success',
          message: 'New group created!',
          timeout: 10000,
          persistent: false
        });
      }
    });
  }
}
