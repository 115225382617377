


























































































































import { Component, Prop } from 'vue-property-decorator';
import MarkdownViewer from '@/components/Library/Markdown/MarkdownViewer.vue';
import PaymentApi from '@/library/api/Payment';
import PaymentEdit from './PaymentEdit.vue';
import UserApi from '@/library/api/User';
import Vue from 'vue';

@Component({
  inject: ['theme'],
  components: { MarkdownViewer, PaymentEdit }
})
export default class Payment extends Vue {
  @Prop({ type: [String, Number], default: 0 }) readonly paymentId!: number|string;

  pApi: PaymentApi = new PaymentApi();
  userApi: UserApi = new UserApi();

  payment: any = null;
  paymentUserAvatarUrl = require('../../assets/account.svg');

  deleteDialog: boolean = false;
  editDialog: boolean = false;

  created() {
    this.loadPayment();
  }

  get paymentEditObject(): any {
    if (! this.payment) { return null; }

    let paymentObject = {
      category: this.payment.category_id,
      // parse amount to string for different 'comma' support in edit dialog
      amount: this.payment.amount + '',
      title: this.payment.title,
      description: this.payment.description,
      valueDate: this.payment.value_date,
      date: null,
      time: null
    };
    if (this.payment.value_date !== undefined) {
      paymentObject.date = this.payment.value_date.substr(0, 10);
      paymentObject.time = this.payment.value_date.substr(11, 5);
    }
    return paymentObject;
  }

  get categoryName(): string {
    if ('group_id' in this.payment &&
        this.$store.getters.groupById(this.payment.group_id) &&
        this.$store.getters.groupById(this.payment.group_id).categories
    ) {
      return this.$store.getters.groupById(this.payment.group_id).categories[this.payment.category_id].name;
    }
    return '';
  }

  get breadCrumbItems(): any[] {
    if (this.payment === {} || ! this.$store.getters.groupById(this.payment['group_id'])) {
      return [];
    }

    const groupName = this.$store.getters.groupById(this.payment['group_id']).name;
    return [
      {
        text: groupName,
        disabled: false,
        url: { name: 'group-dashboard', params: { groupId: this.payment['group_id']} }
      },
      {
        text: this.categoryName,
        disabled: true,
        url: ''
      }
    ]
  }

  get canUserManageTransaction(): boolean {
    const userinfos = this.$store.getters.user.infos;
    if (! userinfos || ! this.payment['group_id'] ) return false;

    const group = this.$store.getters.groupById(this.payment['group_id']);
    if (! group || ! group.userPermissions) return false;

    return group.userPermissions.manageTransactions || userinfos.id === this.payment['user_id'];
  }

  deletePayment(): void {
    this.pApi.deletePayment(this.paymentId as number).then((res) => {
      if (! res.isSuccess()) return;

      this.deleteDialog = false;
      this.$store.commit('addGlobalMessage', {
        type: 'success',
        message: 'The Payment (ID: ' + this.paymentId + ') has been deleted successfully!',
        timeout: 10000,
        persistent: false
      });
      this.$router.back();
    });
  }

  onPaymentEditFinished(editedPayment: any): void {
    this.editDialog = false;
    this.payment = editedPayment;
  }

  loadPayment(): void {
    this.pApi.getPayment(this.paymentId as number).then((response) => {
      this.payment = response.data;
      this.paymentUserAvatarUrl = this.userApi.getUserAvatarUrlWithApiToken(this.payment.user_id);
    });
  }
}
