
























































































































import AmountInput from '../Library/FormInput/AmountInput.vue';
import CategorySelect from '../Library/FormInput/Select/CategorySelect.vue';
import DateTimeDialogSelector from '../Library/FormInput/Select/DateTimeDialogSelector.vue';
import PaymentApi from '../../library/api/Payment';
import MarkdownEditor from '../Library/FormInput/MarkdownEditor.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ApiResponse from '@/library/api/http/ApiResponse';

const BLANK_PAYMENT_OBJECT = {
  category: null,
  amount: null,
  title: null,
  description: null,
  valueDate: null
};

@Component({
  components: { AmountInput, CategorySelect, DateTimeDialogSelector, MarkdownEditor },
})
export default class PaymentEdit extends Vue {
  @Prop({ type: Boolean, required: true }) readonly dialog!: boolean;
  @Prop({ type: [String, Number], required: true }) readonly groupId!: number;
  @Prop({ type: Object, required: false, default: () => { return BLANK_PAYMENT_OBJECT } })
  readonly paymentObject!: any;
  @Prop({ type: Number, required: false, default: 0 }) readonly paymentId!: number;
  @Prop({ type: Boolean, default: false }) readonly createNewPayment!: boolean;

  saving: boolean = false;
  validation = {
    amount: true,
    category: true
  };
  payment = { ...this.paymentObject };

  get title(): string {
    return this.createNewPayment ? 'Create Payment' : 'Edit Payment';
  }

  get formValid(): boolean {
    return Object.values(this.validation).reduce((acc, cur) => { return acc && cur }) && !! this.payment.title;
  }

  onSave(): void {
    this.saving = true;
    const paymentApi = new PaymentApi();
    const payment = {
      categoryId: parseInt(this.payment.category),
      amount: parseFloat(this.payment.amount),
      title: this.payment.title,
      description: this.payment.description,
      belongsToUser: false,
      groupId: this.groupId as number,
      currencyId: 1,
      valueDate: this.payment.valueDate
    };
    if (this.createNewPayment) {
      paymentApi.createPayment(payment).then(response => {
        this.onSaveFinished(response);
      });
    } else {
      paymentApi.editPayment(this.paymentId, payment).then(response => {
        this.onSaveFinished(response);
      });
    }
  }

  onSaveFinished(response: ApiResponse): void {
    this.saving = false;

    if (! response.isSuccess()) {
      this.$store.commit('addGlobalMessage', {
        type: 'error',
        message: 'Payment could not be saved!',
        timeout: 10000,
        persistent: false
      });
      return;
    }

    this.$store.commit('addGlobalMessage', {
      type: 'success',
      message: 'Payment successfully saved!',
      timeout: 10000,
      persistent: false
    });

    const page = this.$store.getters.groupById(this.groupId).payments.currentPage;
    this.$store.dispatch('loadPaymentsInGroup', { groupId: this.groupId, page });
    this.$emit('saved', response.data.payment);
  }
}
