














































import Vue from 'vue';
import CategorySelect from '@/components/Library/FormInput/Select/CategorySelect.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { JsonFilter } from '@/library/filter/FilterCollection';
import UserSelect from '@/components/Library/FormInput/Select/UserSelect.vue';
import DateRangeSelector from '@/components/Library/FormInput/Select/DateRangeSelector.vue';

@Component({
  name: 'FilterGroup',
  components: {DateRangeSelector, UserSelect, CategorySelect }
})
export default class FilterGroup extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: JsonFilter[];
  @Prop({ type: Number, required: true }) groupId!: Number;
  i_filters = this.value;

  get filters(): JsonFilter[] {
    return this.value;
  }

  set filters(filters: JsonFilter[]) {
    this.$emit('input', filters);
  }

  @Watch('i_filters', { deep: true })
  onInternalFilterChange() {
    this.filters = this.i_filters;
  }

  onChange() {
    this.$emit('changed');
  }
}
