


























































import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class GroupSwitcher extends Vue {
  switcherSheet: boolean = false;

  get selectedGroup(): number {
    return this.$store.state.selectedGroup;
  }

  get selectedGroupDetails(): any {
    return Object.values(this.groups).find(group => {
      return group.id === this.selectedGroup;
    });
  }

  get groups(): { [key: number]: any } {
    return this.$store.getters.groups;
  }

  onSelectGroup(groupId: number): void {
    this.$store.commit('setSelectedGroup', groupId);
    this.$emit('switch-group', groupId);
    this.switcherSheet = false;
  }
}
