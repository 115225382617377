import { store } from '@/store';
import { ROUTE_LOGIN, ROUTE_REGISTER } from './index';
import {NavigationGuardNext, Route} from 'vue-router';

export default (to: Route, from: Route, next: NavigationGuardNext) => {
    const EXCLUDED_ROUTES = [ ROUTE_LOGIN, ROUTE_REGISTER ];

    if (
        store.getters.isUserAuthenticated || 
        store.getters.isLoginWithoutCredentialsInProcess ||
        EXCLUDED_ROUTES.includes(to.name ?? '')
    ) {
        next();
    } else {
        next('/login');
    }
}