import { JsonFilter } from '../FilterCollection';
import FilterModel, { FilterType } from '../FilterModel';

export interface JsonCategoryFilter extends JsonFilter {
  categories: number[]
}

export default class CategoryFilter implements FilterModel<JsonCategoryFilter> {
  filterType: FilterType = 'Category';

  private _categories: Array<number> = [];

  get categories(): Array<number> {
    return this._categories;
  }

  set categories(categories: Array<number>) {
    this._categories = categories;
  }

  isEmpty(): boolean {
    return this._categories.length === 0;
  }

  toUriObject(): Object {
    return { categories: this.categories }
  }

  toJsonFilter(): JsonCategoryFilter {
    return {
      type: this.filterType.toString(),
      categories: this._categories
    }
  }

  loadFromJsonFilter(filter: JsonCategoryFilter): void {
    this.categories = filter.categories;
  }
}