import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// Color and icon import
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

// check if dark mode setting is set (in local storage)
let darkMode = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_KEY_DARKMODE) ?? 'false');
if (darkMode === null || darkMode === undefined) {
  darkMode = true;
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // materialdesignicons
  },
  theme: {
    dark: darkMode,
    themes: {
      dark: {
        primary: colors.blue.darken2,
        accent: colors.red.accent2,
        secondary: colors.grey.lighten1,
        info: colors.blue.lighten1,
        warning: colors.amber.darken2,
        error: colors.red.accent4,
        success: colors.green.lighten2
      }
    },
    options: {
      customProperties: true
    }
  },
});
