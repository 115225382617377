

































































import Vue from 'vue';
import moment from 'moment';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { JsonDateRangeFilter, RelativeDateRange } from '@/library/filter/types/DateRangeFilter';

@Component
export default class DateRangeSelector extends Vue {
  @Prop({ default: null }) readonly value!: JsonDateRangeFilter;
  @Prop() readonly groupId!: Number;
  @Prop({ default: null }) readonly label!: String;
  @Prop({ type: Boolean, default: false }) readonly multiple!: Boolean;
  @Prop({ type: Boolean, default: false }) readonly hideValidation!: Boolean;

  rangeSelector: RelativeDateRange|null = this.value.range;
  customRange: string[] = [];
  customRangeSelectorDialog: boolean = false;
  balanceTimeRangeOptions: { key: RelativeDateRange, text: string }[] = [
    { key: 'custom', text: 'Custom' },
    { key: 'today', text: 'Today' },
    { key: 'last-7-days', text: 'Last 7 days' },
    { key: 'last-30-days', text: 'Last 30 days' },
    { key: 'current-month', text: 'Current Month' },
    { key: 'last-month', text: 'Last month' },
    { key: 'current-year', text: 'Current Year' },
    { key: 'last-year', text: 'Last Year' },
  ];

  created() {
    if (this.value.range === 'custom' && this.value.startDateTime != null && this.value.endDateTime != null) {
      this.customRange = [ this.value.startDateTime, this.value.endDateTime ];
    }
  }

  @Watch('rangeSelector')
  onRangeSelectionChange(newRange: RelativeDateRange|undefined, oldRange: RelativeDateRange|undefined) {
    if (newRange === undefined) {
      // reset filter if input was cleared
      this.updateFilterVModel(null);
    } else if (newRange !== oldRange && newRange === 'custom') {
      // open date range dialog on selection "custom"
      this.customRangeSelectorDialog = true;
    } else if (newRange !== oldRange) {
      // update filter with new range; start and end will be calculated
      // in the filter model, thus they are set to null here
      this.updateFilterVModel(newRange);
    }

    if (newRange !== 'custom') {
      this.customRange = [];
    }
  }

  get displayStartDate(): string {
    return !!this.value.startDateTime ? moment(this.value.startDateTime).format('DD.MM.YYYY') : '#';
  }

  get displayEndDate(): string {
    return !!this.value.endDateTime ? moment(this.value.endDateTime).format('DD.MM.YYYY'): '#';
  }

  onCustomRangeSave() {
    // @ts-ignore
    this.$refs.dialog.save(this.customRange);
    const range = this.customRange;

    // should not happen
    if (range.length === 0) {
      return;
    }

    // start date should equal end date if not defined
    if (range.length === 1) {
      range.push(range[0]);
    }

    this.updateFilterVModel(this.rangeSelector, range[0] + ' 00:00:00', range[1] + ' 23:59:59');
  }

  onCustomRangeCancel() {
    // remove filter, as otherwise an invalid date (i.e., no date at all)
    // would be chosen for the custom date
    this.updateFilterVModel(null);
    this.customRange = [];
    this.rangeSelector = null;

    this.customRangeSelectorDialog = false;
  }

  updateFilterVModel(
      range: RelativeDateRange|null = null,
      startDateTime: string|null = null,
      endDateTime: string|null = null
  ): void {
    const newRangeFilter = { ...this.value };
    newRangeFilter.range = range;
    newRangeFilter.startDateTime = startDateTime;
    newRangeFilter.endDateTime = endDateTime;

    this.$emit('input', newRangeFilter);
  }
}
