



































import Component from 'vue-class-component';
import DashboardBalanceWidget from '@/components/Group/Dashboard/BalanceWidget.vue';
import GroupedCategorySpendingWidget from '@/components/Group/Dashboard/GroupedCategorySpendingWidget.vue';
import FilterCollection from '@/library/filter/FilterCollection';
import FilterGroup from '@/components/Library/Filter/FilterGroup.vue';
import FilterPageHeader from '@/components/Library/Filter/FilterPageHeader.vue';
import Vue from 'vue';

@Component({
  components: { GroupedCategorySpendingWidget, DashboardBalanceWidget, FilterPageHeader, FilterGroup }
})
export default class GroupDashboard extends Vue {
  filterCollection: FilterCollection = new FilterCollection();

  created() {
    this.filterCollection = new FilterCollection('DateRange', 'Category', 'User');
    this.updateDashboard();
  }

  get groupId(): number {
    return this.$store.state.selectedGroup;
  }

  updateDashboard(): void {
    // call update method on each widget
    this.$nextTick(() => {
      Object.values(this.$refs).forEach((widget) => {
        // @ts-ignore
        widget.update();
      });
    });
  }
}
