import { JsonFilter } from '../FilterCollection';
import FilterModel, { FilterType } from '../FilterModel';
import moment from 'moment';

export interface JsonDateRangeFilter extends JsonFilter {
  range: RelativeDateRange|null,
  startDateTime: string|null,
  endDateTime: string|null,
}

export type RelativeDateRange = (
  'custom' | 'today' | 'last-7-days' | 'last-30-days' |
  'current-month' | 'last-month' | 'current-year' | 'last-year'
);

export default class DateRangeFilter implements FilterModel<JsonDateRangeFilter> {
  filterType: FilterType = 'DateRange';

  private _range: RelativeDateRange|null = null;
  private _startDateTime: string|null = null;
  private _endDateTime: string|null = null;

  get range(): RelativeDateRange|null {
    return this._range;
  }

  set range(range: RelativeDateRange|null) {
    this._range = range;
    this.mapRelativeRangeToStartAndEnd();
  }

  get startDateTime(): string|null {
    return this._startDateTime;
  }

  set startDateTime(startDateTime: string|null) {
    this._range = 'custom';
    this._startDateTime = startDateTime;
  }

  get endDateTime(): string|null {
    return this._endDateTime;
  }

  set endDateTime(endDateTime: string|null) {
    this._range = 'custom';
    this._endDateTime = endDateTime;
  }

  isEmpty(): boolean {
    // if range is other than custom, start and end should always be set as well
    return this._startDateTime === null || this._endDateTime === null;
  }

  toUriObject(): Object {
    return {
      dateTimeRange: {
        start: this.startDateTime,
        end: this.endDateTime,
      }
    }
  }
  
  toJsonFilter(): JsonDateRangeFilter {
    return {
      type: this.filterType.toString(),
      range: this.range,
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
    }
  }

  /**
   * Load the DateRange filter data from a respective Json representation.
   * <p>Note: If the range-param ({@see RelativeDateRange}) is set to something
   * other than 'custom' or null, the start and end dates are remapped according
   * to the selected relative range.
   *
   * @param filter JSON data to load filter from.
   */
  loadFromJsonFilter(filter: JsonDateRangeFilter): void {
    this._range = filter.range;
    this._startDateTime = filter.startDateTime;
    this._endDateTime = filter.endDateTime;

    this.mapRelativeRangeToStartAndEnd();
  }

  private mapRelativeRangeToStartAndEnd(): void {
    const dayFormat = 'YYYY-MM-DD';
    let start = '';
    let end = '';

    switch (this.range) {
      case null:
      case 'custom':
        // do nothing: start and end should be set manually or filter is not even "used"
        return;
      case 'today':
        start = moment().format(dayFormat);
        end = start;
        break;
      case 'last-7-days':
        start = moment().subtract(7, 'days').format(dayFormat);
        end = moment().format(dayFormat);
        break;
      case 'last-30-days':
        start = moment().subtract(30, 'days').format(dayFormat);
        end = moment().format(dayFormat);
        break;
      case 'current-month':
        start = moment().format('YYYY-MM[-01]');
        end = moment().format(dayFormat);
        break;
      case 'last-month':
        start = moment().subtract(1, 'month').startOf('month').format(dayFormat);
        end = moment().subtract(1, 'month').endOf('month').format(dayFormat);
        break;
      case 'current-year':
        start = moment().format('YYYY[-01-01]');
        end = moment().format(dayFormat);
        break;
      case 'last-year':
        start = moment().subtract(1, 'year').format('YYYY[-01-01]');
        end = moment().subtract(1, 'year').format('YYYY[-12-31]');
        break;
    }

    this._startDateTime = start + ' 00:00:00';
    this._endDateTime = end + ' 23:59:59';
  }
}