













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Select from './Select.vue';

@Component({
  components: { Select }
})
export default class CategorySelect extends Vue {
  @Prop({ default: null }) readonly value!: Number | Array<any> | null;
  @Prop() readonly groupId!: Number;
  @Prop({ default: null }) readonly label!: String;
  @Prop({ type: Boolean, default: false }) readonly multiple!: Boolean;
  @Prop({ type: Boolean, default: false }) readonly hideValidation!: Boolean;
  
  get categoryItems(): Array<any> {
    const group = this.$store.getters.groupById(this.groupId)
    if (! group || ! group.categories) return [];

    const categories: Array<{ [key: string]: any }> = Object.values(group.categories);
    const items = [];
    for (const category of categories) {
      items.push({
        value: category.id,
        text: category.name
      });
    }

    return items;
  }

  get selectedCategories(): Number | Array<any> | null {
    return this.value;
  }

  set selectedCategories(value: Number | Array<any> | null) {
    this.$emit('input', value);
  }
  
  get selectLabel(): String {
    if (this.label) { return this.label; }
    return this.multiple ? 'Categories' : 'Category';
  }
}
