






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FilterCollection from '../../../library/filter/FilterCollection';
import GroupStatisticsApi from '@/library/api/group/Statistics';
import PieChart from '../../Library/Charts/PieChart.vue';
import { generateRandomRequestId } from '@/store';
import { formatMoney } from '@/filters/generalfilters';

@Component({
  components: { PieChart }
})
export default class GroupedCategorySpendingWidget extends Vue {
  @Prop({ type: FilterCollection, required: true }) filter!: FilterCollection;
  @Prop({ type: Number, required: true }) groupId!: number;
  statisticsApi: GroupStatisticsApi = new GroupStatisticsApi();
  loading: boolean = true;
  lastRequestId: string = '';
  chartData: { datasets: any[], labels: string[] } = {
    datasets: [],
    labels: [],
  };
  chartStyles = {
    height: '250px',
    position: 'relative',
  };
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        /** Function to format the money of the tooltip properly. */
        label: function(tooltipItem: any, data: any): string {
          let label = data.labels[tooltipItem.index] ?? '';
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ?? 0;

          if (label) {
            label += ': ';
          }
          return `${label}${formatMoney(value)} €`;
        }
      }
    }
  }

  get groupCategories() {
    return this.$store.getters.groupById(this.groupId).categories;
  }

  update(): void {
    this.loading = true;
    const requestId = this.lastRequestId = generateRandomRequestId();
    this.statisticsApi.getGroupGroupedSpending(this.groupId, 'category_id', this.filter).then(response => {
      if (
        this.lastRequestId !== requestId ||
        !response.isSuccess()
      ) {
        return;
      }

      this.updateChart(response.data.grouped_spending);
      this.loading = false;
    });
  }

  updateChart(groupedSpending: any[]): void {
    if (groupedSpending.length === 0) {
      this.chartData.datasets = [];
      this.chartData.labels = [];
      return;
    }

    const newDataset: any = {
      data: [],
      backgroundColor: [],
    };
    const labels: string[] = [];

    groupedSpending.forEach(category => {
      newDataset.data.push(category.amount_sum);
      newDataset.backgroundColor.push(this.groupCategories[category.category_id].color);
      labels.push(this.groupCategories[category.category_id].name);
    });

    this.chartData.datasets = [ newDataset ];
    this.chartData.labels = labels;
  }
}
