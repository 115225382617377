












































































































































import AddEditCategory from './Settings/AddEditCategory.vue';
import {Component, Watch} from 'vue-property-decorator';
import EditUser from './Settings/EditUser.vue';
import GroupApi from '@/library/api/group/Group';
import InvitationSection from './Settings/sections/InvitationSection.vue';
import RemoveCategory from './Settings/RemoveCategory.vue';
import Vue from 'vue';

@Component({
  name: 'GroupSettings',
  components: {AddEditCategory, EditUser, RemoveCategory, InvitationSection},
})
export default class GroupSettings extends Vue {
  groupApi: GroupApi = new GroupApi();

  permissions = {
    inviteUsers: false,
    manageCategories: false,
    manageUserPermissions: false,
    removeUsers: false
  };

  categoryPanel = {
    tableHeaders: [
      {text: 'Name', value: 'name'},
      {text: 'Description', value: 'description'},
      {text: 'Actions', value: 'action', align: 'right', sortable: false, width: 110}
    ],
    groupCategories: []
  };

  memberPanel = {
    tableHeaders: [
      {text: 'Username', value: 'name', align: 'left'},
      {text: 'E-Mail', value: 'email', align: 'left'},
      {text: 'Actions', value: 'action', align: 'right', sortable: false},
    ],
    groupMembers: [],
    groupOwner: 0,
  };

  created() {
    this.loadData();
  }

  @Watch('groupId')
  onGroupChange() {
    this.loadData();
  }

  get groupId(): number {
    return this.$store.state.selectedGroup;
  }

  loadData(): void {
    // get group categories and group members
    this.loadCategories();
    this.groupApi.getGroupMembers(this.groupId).then((response) => {
      if (! response.isSuccess()) return;
      this.memberPanel.groupMembers = response.data.users;
      this.memberPanel.groupOwner = response.data.group_owner;
    });

    // get permissions of the user for the group
    this.groupApi.getUserPermissions(this.groupId).then((res) => {
      if (res.isSuccess()) {
        this.permissions = res.data.group_permissions;
      }
    });
  }

  /** Add new category button clicked in the category panel */
  clickAddCategory(): void {
    const cmp = this.$refs.addEditCategoryComponent as AddEditCategory;
    cmp.initialize(this.groupId, 'ADD', null);
  }

  /** Edit button clicket in the category panel */
  clickEditCategory(item: any): void {
    const cmp = this.$refs.addEditCategoryComponent as AddEditCategory;
    cmp.initialize(this.groupId, 'EDIT', item);
  }

  /** Delete button clicked in the category panel */
  clickRemoveCategory(item: any): void {
    const cmp = this.$refs.removeCategoryComponent as RemoveCategory;
    cmp.initialize(this.groupId, item);
  }

  /** Load the group categories via the API helper. */
  loadCategories(): void {
    const me = this;
    this.groupApi.getGroupCategories(this.groupId).then((response) => {
      me.categoryPanel.groupCategories = response.data.categories;
    });
  }

  /** Edit button clicked in the member grid. */
  clickEditMember(item: any): void {
    const cmp = this.$refs.editUserComponent as EditUser;
    cmp.startEditUser(item, this.groupId);
  }

  /** Invite new user button clicked in the member panel */
  clickInviteMember(): void {
    alert('User invitations are not yet available.');
  }

  /** Delete button clicked in the member grid. */
  clickRemoveMember(item: any): void {
    alert('User removals are not yet available.');
  }
}
