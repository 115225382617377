













































































import { Component, Watch } from 'vue-property-decorator';
import UserApi from '@/library/api/User';
import Vue from 'vue';

@Component
export default class ProfileMenu extends Vue {
  dialog: boolean = false;
  userAvatarBase64: string|null = null;

  mainLinks = [
    { name: 'Account Settings', link: '/profile', icon: 'mdi-account-cog-outline' },
    { name: 'Changelog / News', link: '/changelog', icon: 'mdi-stack-exchange' },
    { name: 'Contact', link: '/contact', icon: 'mdi-email-outline' }
  ];

  get user() {
    return this.$store.state.user;
  }

  @Watch('user')
  onUserChange(usr: any) {
    if (! usr || ! usr.infos) return;

    new UserApi().getUserAvatarAsBase64(usr.infos.id).then(avatar => {
      this.userAvatarBase64 = avatar as string;
    });
  }

  close(): void {
    this.dialog = false;
  }

  onLogout(): void {
    this.$store.dispatch('logout');
  }
}
