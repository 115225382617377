













































import Component from 'vue-class-component';
import GroupApi from '@/library/api/group/Group';
import RULES from '@/library/validation/rules';
import Vue from 'vue';

@Component
export default class JoinNewGroup extends Vue {
  id: number|null = null;
  token: string|null = null;

  valid: boolean = false;
  rules = RULES;

  saving: boolean = false;
  error: any = null;

  onCreateGroup(): void {
    if (! this.id || ! this.token) return;

    this.saving = true;
    new GroupApi().joinGroup(this.id, this.token).then(response => {
      this.saving = false;
      if (! response.isSuccess()) {
        this.error = true;
      } else {
        this.error = false;

        // reset form
        this.id = null;
        this.token = null;

        this.$store.dispatch('loadUserGroups');
        this.$store.commit('addGlobalMessage', {
          type: 'success',
          message: 'You joined the group!',
          timeout: 10000,
          persistent: false
        });
      }
    }).catch(() => { this.error = true; this.saving = false; });
  }
}
