import FilterModel, { FilterType } from './FilterModel';
import CategoryFilter from './types/CategoryFilter';
import DateRangeFilter from './types/DateRangeFilter';
import UserFilter from '@/library/filter/types/UserFilter';

export interface JsonFilter {
  name?: string,
  type: string,
}

export default class FilterCollection {
  filters!: Map<String, FilterModel<any>>;

  constructor(...filterTypesToCreate: FilterType[]) {
    this.filters = new Map();

    filterTypesToCreate.forEach(type => {
      this.filters.set(type.toString(), FilterCollection.createDefaultFilterByType(type))
    });
  }

  containsFilterType(type: FilterType): boolean {
    // check that at least one filter of given type is available
    return this.getFiltersOfType(type).length > 0;
  }

  getFiltersOfType(type: FilterType): FilterModel<any>[] {
    return Array.from(this.filters.values())
                .filter(filter => filter.filterType === type);
  }

  generateUriObject(): Object {
    let obj = {};
    this.filters.forEach(filter => {
      obj = { ...obj, ...filter.toUriObject() };
    });
    return obj;
  }

  allFiltersEmpty(): boolean {
    return Array.from(this.filters.values())
      .reduce((allEmpty: boolean, filter: FilterModel<JsonFilter>): boolean => {
        return allEmpty && filter.isEmpty()
      }, true);
  }

  toJsonFilterArray(): JsonFilter[] {
    return Array.from(this.filters.values()).map(filter => filter.toJsonFilter());
  }

  public static fromJsonFilterArray(filterArray: JsonFilter[]): FilterCollection {
    const collection = new this();
    filterArray.forEach(filter => {
      let filterModel: FilterModel<any>|null = null;

      switch (filter.type) {
        case 'Category':
          filterModel = new CategoryFilter();
          break;
        case 'DateRange':
          filterModel = new DateRangeFilter();
          break;
        case 'User':
          filterModel = new UserFilter();
          break;
      }

      if (! filterModel) {
        return;
      }

      filterModel.loadFromJsonFilter(filter);
      collection.filters.set(filter.type, filterModel);
    });
    return collection;
  }

  private static createDefaultFilterByType(type: FilterType): FilterModel<any> {
    switch (type) {
      case 'Category':
        return new CategoryFilter();
      case 'DateRange':
        return new DateRangeFilter();
      case 'User':
        return new UserFilter();
      default:
        throw new Error('Unknown Filter Type!');
    }
  }
}