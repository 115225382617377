












































































































































































































































































































import Component from 'vue-class-component';
import UserApi from '@/library/api/User';
import Vue from 'vue';

@Component({ name: 'Profile' })
export default class Profile extends Vue {
  userApi: UserApi = new UserApi();

  // load default avatar to be displayed until user avatar loaded from api
  userAvatarUrl = require('../assets/account.svg');

  avatarChange = {
    formValid: false,
    changeAvatarDialog: false,
    deleteAvatarDialog: false,
    newAvatarFile: null,
    uploadInProcess: false,
    validationRules: [
      (value: any): boolean|string => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ]
  };
  emailChange = {
    formValid: false,
    email: "",
    validationRules: [
      (value: any): boolean|string => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      }
    ],
    error: false,
    alertMsg: ""
  };
  passwordChange = {
    formValid: false,
        oldPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
        error: false,
        alertMsg: "",
        validationRules: [(v: any): boolean|string => !!v || 'Required!']
  }

  created() {
    // get current email
    this.loadEmailAddress();

    // get user avatar url
    this.userApi.getUserInfo().then(response => {
      this.userAvatarUrl = this.userApi.getUserAvatarUrlWithApiToken(response.data.me.id);
    });
  }

  get darkModeEnabled(): boolean {
    return this.$vuetify.theme.dark;
  }

  set darkModeEnabled(_: boolean) {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    localStorage.setItem(process.env.VUE_APP_LOCALSTORAGE_KEY_DARKMODE, JSON.stringify(this.$vuetify.theme.dark));
  }

  get isEmailVerified(): boolean {
    if (! this.$store.state.user.infos || ! this.$store.state.user.infos.email_verified_at) return false;
    return this.$store.getters.user.infos.email_verified_at !== null;
  }

  onRequestNewVerificationMail(): void {
    this.userApi.requestNewVerificationMail().then(response => {
      if (! response.isSuccess()) return;
      this.$store.commit('addGlobalMessage', {
        type: 'success',
        message: 'New verification mail will be send...',
        timeout: 10000,
        persistent: false
      });
    });
  }

  onEmailSave(): void {
    this.userApi.changeUserEmail(this.emailChange.email).then(response => {
      if (response.isSuccess()) {
        this.$store.commit('addGlobalMessage', {
          type: 'success',
          message: response.messageCode,
          timeout: 10000,
          persistent: false
        });
        this.loadEmailAddress();
      } else {
        this.emailChange.error = true;
        this.emailChange.alertMsg = response.messageCode;
      }
    });
  }

  onPasswordSave(): void {
    if (this.passwordChange.newPassword !== this.passwordChange.newPasswordRepeat) {
      this.passwordChange.error = true;
      this.passwordChange.alertMsg = "The new passwords are not equal!";
      return;
    } else {
      this.passwordChange.error = false;
    }

    this.userApi.changeUserPassword(
        this.passwordChange.oldPassword,
        this.passwordChange.newPassword
    ).then(response => {
      if (response.isSuccess()) {
        this.$store.commit('addGlobalMessage', {
          type: 'success',
          message: 'Password changed successfully. You have to relogin on all your devices! You will be logged out in 10 seconds...',
          timeout: 10000,
          persistent: false
        });
        this.passwordChange.error = false;
        this.passwordChange.oldPassword = "";
        this.passwordChange.newPassword = "";
        this.passwordChange.newPasswordRepeat = "";
        setTimeout(() => this.$store.dispatch('logout'), 10000);
      } else {
        this.passwordChange.error = true;
        this.passwordChange.alertMsg = response.messageCode;
      }
    });
  }

  onAvatarUpload(): void {
    this.avatarChange.uploadInProcess = true;
    this.userApi.uploadNewUserAvatar(this.avatarChange.newAvatarFile).then(response => {
      this.avatarChange.uploadInProcess = false;
      this.avatarChange.changeAvatarDialog = false;
      this.avatarChange.newAvatarFile = null;

      this.userAvatarUrl += '&changed=' + Math.round(Math.random() * 100);
    });
  }

  onAvatarDelete(): void {
    this.avatarChange.uploadInProcess = true;
    this.userApi.deleteUserAvatar().then(response => {
      this.avatarChange.uploadInProcess = false;
      this.avatarChange.deleteAvatarDialog = false;
      this.avatarChange.newAvatarFile = null;

      this.userAvatarUrl += '&changed=' + Math.round(Math.random() * 100)
    });
  }

  loadEmailAddress(): void {
    this.userApi.getUserInfo().then(response => {
      this.emailChange.email = response.data.me.email;
    });
  }
}
