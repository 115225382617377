import { AxiosResponse } from "axios";

export default class ApiResponse {
    private success: boolean;
    private _messageCode: string;
    private apiData: any;

    originalResponse: AxiosResponse;

    /**
     * Create new response with response-data from api-request.
     *
     * @param { AxiosResponse } response response data of the api request
     */
    constructor(response: AxiosResponse) {
        // set main response data
        this.success = response.data.success;
        this._messageCode = response.data.message_code;
        this.apiData = response.data.data;

        // set additional response data
        this.originalResponse = response;
    }

    /** Check whether the api marked the response as success or not. */
    isSuccess(): boolean {
        return this.success;
    }

    /** Get the actual response data from the api. */
    get data(): any {
        return this.apiData;
    }

    get messageCode(): string {
        return this._messageCode;
    }
    
}