import { store } from '@/store';
import { ROUTE_GROUP_DASHBOARD } from './index';
import {NavigationGuardNext, Route} from 'vue-router';

const REDIRECT_TO = ROUTE_GROUP_DASHBOARD;

export default (to: Route, from: Route, next: NavigationGuardNext) => {
    if (store.getters.isUserAuthenticated) {
        next({ name: REDIRECT_TO });
    } else {
        next();
    }
}