







import Component from 'vue-class-component';
import CreateGroup from './CreateGroup.vue';
import JoinGroup from './JoinGroup.vue';
import Vue from 'vue';

@Component({
  name: 'NewGroup',
  components: { CreateGroup, JoinGroup }
})
export default class NewGroup extends Vue { }
