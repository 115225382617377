















































































import { Component, Prop } from 'vue-property-decorator';
import GroupApi from '@/library/api/group/Group';
import RULES from '@/library/validation/rules';
import Vue from 'vue';

@Component
export default class NewInvitationDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean;

  loading: boolean = false;

  rules = RULES;
  formValid: boolean = false;
  dateDialog: boolean = false;

  multiUse: boolean = false;
  name: string = '';
  email: string = '';
  expiryDate: string|null = null;

  responseError: boolean = false;
  errorMessage: string = '';

  invitationCreated: boolean = false;
  generatedInvitationId: number|null = null;
  generatedInvitationToken: string|null = null;

  /**
   * Handler for creating the new invitation.
   * Displays error on failure and the generated code on success.
   */
  onCreateInvitation() {
    if (! this.formValid) {
      return;
    }
    this.loading = true;

    const groupId = this.$store.state.selectedGroup;
    const usageType = this.multiUse ? 'MULTI' : 'SINGLE';
    const email = this.email ?? null;
    const expiryDate = this.expiryDate ?? null;

    new GroupApi().generateGroupInvitation(groupId, this.name, usageType, email, expiryDate).then(response => {
      this.loading = false;

      // success: close dialog
      if (response.isSuccess()) {
        this.$emit('invitation-created');

        if (! this.email) {
          this.invitationCreated = true;
          this.generatedInvitationId = response.data.invitation_id;
          this.generatedInvitationToken = response.data.token;
        }

        this.multiUse = false;
        this.name = '';
        this.email = '';
        this.expiryDate = null;
        this.responseError = false;
        this.errorMessage = '';

        return;
      }

      // no success: show error
      this.responseError = true;
      if (response.messageCode === 'MAX_AMOUNT_OF_MEMBERS_REACHED') {
        this.errorMessage = 'The group reached the maximum amount of members.';
      } else if (
          response.messageCode === 'TOKEN_GENERATION_FAILED' &&
          response.data.validation_errors_merged.includes('USER_NOT_FOUND')
      ) {
        this.errorMessage = 'The email you provided is not connected to any Accountrol account!'
      }
    });
  }
}
