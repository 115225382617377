
import { Pie, mixins } from 'vue-chartjs';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

const { reactiveProp } = mixins;

@Component({
  mixins: [ Pie, reactiveProp ],
})
export default class PieChart extends Vue {
  @Prop({ type: Object, default: null }) chartOptions: any;

  mounted() {
    // @ts-ignore
    this.renderChart(this.chartData, this.chartOptions);
  }
}
