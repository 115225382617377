













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Select from './Select.vue';

@Component({
  components: { Select }
})
export default class UserSelect extends Vue {
  @Prop({ default: null }) readonly value!: Number | Array<any> | null;
  @Prop() readonly groupId!: Number;
  @Prop({ default: null }) readonly label!: String;
  @Prop({ type: Boolean, default: false }) readonly multiple!: Boolean;
  @Prop({ type: Boolean, default: false }) readonly hideValidation!: Boolean;

  get userItems(): Array<any> {
    const group = this.$store.getters.groupById(this.groupId)
    if (! group || ! group.members) return [];

    const items = [];
    for (const member of group.members) {
      items.push({
        value: member.user_id,
        text: member.name
      });
    }

    return items;
  }

  get selectedUsers(): Number | Array<any> | null {
    return this.value;
  }

  set selectedUsers(value: Number | Array<any> | null) {
    this.$emit('input', value);
  }

  get selectLabel(): String {
    if (this.label) { return this.label; }
    return this.multiple ? 'Users' : 'User';
  }
}
