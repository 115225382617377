//
// General/ "Misc" Filters
//

export function formatMoney(value: string) {
    return Number.parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        useGrouping: true
    })
}
