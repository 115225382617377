





































































import { Component, Watch } from 'vue-property-decorator';
import RULES from '../library/validation/rules';
import Vue from 'vue';

@Component({ name: 'Login' })
export default class Login extends Vue {
  email: string = '';
  password: string = '';
  rememberMe: boolean = false;

  rules = RULES;
  valid: boolean = false;

  loginInProcess: boolean = false;

  get user(): any {
    return this.$store.state.user;
  }

  @Watch('user')
  onUserChange(val: any): void {
    // Redirect to start-page when user is logged in
    if (val !== null && val !== undefined) {
      this.$router.push('/');
    }
  }

  doLogin() {
    this.loginInProcess = true;
    this.$store.dispatch(
        'doUserLogin',
        { email: this.email, password: this.password, rememberMe: this.rememberMe }
    ).then(() => {
      this.loginInProcess = false;
    });
  }
}
