

































import Component from 'vue-class-component';
import GroupAdvancedMenuCmp from './GroupAdvancedMenu.vue';
import Vue from 'vue';

@Component({
  components: { GroupAdvancedMenuCmp }
})
export default class GroupAdvancedMenu extends Vue {
  groupAdvancedMenu: boolean = false;
}
