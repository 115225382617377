import Api from './Api';
import ApiRequest from './http/ApiRequest';
import ApiResponse from './http/ApiResponse';

const LS_KEY_REMEMBER_ME_ENABLED = process.env.VUE_APP_LOCALSTORAGE_KEY_REMEMBER_ME_ENABLED;

export default class AuthApi extends Api {

    /**
     * Try to login user without credentials.
     * Login is performed with a still valid jwt token from the cookie if possible,
     * otherwise the login via the rememberMe feature is used. If both is not
     * possible/ fails, no login is performed at all.
     *
     * @returns {Promise<ApiResponse>}
     */
    async tryUserLoginWithoutCredentials(): Promise<ApiResponse> {
        // Check, if a valid token is still available within the cookie
        let token = null;
        try {
            token = await new ApiRequest('GET')
                .setUrl(Api.buildApiUrl('/uac/login/currentToken'))
                .withoutGlobalExceptionHandling()
                .execute();
        } catch { }
        // Generate fresh token, if a still valid token has been found by the api
        if (token) {
            return new Promise(resolve => {
                new ApiRequest('POST')
                    .setUrl(Api.buildApiUrl('/uac/login/refresh'))
                    .withoutGlobalExceptionHandling()
                    .execute()
                    .then(response => {
                        resolve(response);
                    });
            });
        }

        // Try to login with rememberMe, if no valid token has been found by the api
        const rememberMeEnabled = localStorage.getItem(LS_KEY_REMEMBER_ME_ENABLED);
        return new Promise(resolve => {
            if (! rememberMeEnabled) {
                // @ts-ignore
                resolve({ isSuccess: () => { return false } });
            } else {
                new ApiRequest('POST')
                    .setUrl(Api.buildApiUrl('/uac/login/rememberMe'))
                    .withoutGlobalExceptionHandling()
                    .execute()
                    .then(response => {
                        localStorage.setItem(LS_KEY_REMEMBER_ME_ENABLED, JSON.stringify(response.isSuccess()));
                        resolve(response);
                    });
            }
        });
    }

    /**
     * Perform user-login with login-credentials (email & password).
     * The localStorage will be used to save, whether rememberMe has been enabled
     * or not.
     *
     * @param email                 email for login
     * @param password              password for login
     * @param rememberMe            boolean whether to request for rememberMe or not
     * @returns {Promise<Object>}   Token data returned by the api
     */
    async userLoginWithCredentials(email: string, password: string, rememberMe: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            new ApiRequest('POST')
                .setUrl(Api.buildApiUrl('/uac/login'))
                .setData({ email: email, password: password, rememberMe: rememberMe })
                .withoutGlobalExceptionHandling()
                .execute()
                .then(response => {
                    if (! response.isSuccess()) {
                        reject('Login failed.');
                        return;
                    }

                    localStorage.setItem(LS_KEY_REMEMBER_ME_ENABLED, rememberMe && response.data.rememberMe);
                    resolve(response.data);
                })
                .catch(() => {
                    reject('Login failed.');
                });
        });
    }

    /** Send logout-request to the api. */
    async logout(): Promise<ApiResponse|any> {
        return new Promise((resolve, reject) => {
            new ApiRequest('POST')
                .setUrl(Api.buildApiUrl('/uac/logout'))
                .execute()
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}
