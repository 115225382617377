import Api from '../Api';
import ApiRequest from '../http/ApiRequest';
import ApiResponse from '../http/ApiResponse';

export interface Category {
    name: string,
    description: string,
    color: string,
}

export default class GroupApi extends Api {

    async getGroupMembers(groupId: Number): Promise<ApiResponse> {
        return new ApiRequest('GET')
            .setUrl(Api.buildApiUrl('/group/' + groupId + '/members'))
            .execute();
    }

    /**
     * Get all categories of a specific group.
     *
     * @param groupId of the group
     * @returns {Promise<ApiResponse>}
     */
    async getGroupCategories(groupId: Number): Promise<ApiResponse> {
        return new ApiRequest('GET')
            .setUrl(Api.buildApiUrl('/group/' + groupId + '/categories'))
            .execute();
    }

    /**
     * Add new Category to a specific group.
     *
     * @param groupId
     * @param data {{ name: String, description: String }} details of the new group
     * @returns {Promise<ApiResponse>}
     */
    async addGroupCategory(groupId: Number, data: Category): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/group/' + groupId + '/category'))
            .setData(data)
            .execute();
    }

    /**
     * Edit a specific category of a group.
     *
     * @param groupId
     * @param categoryId of the category that should be edited
     * @param data post data with the category update-data
     * @returns {Promise<ApiResponse>}
     */
    async editGroupCategory(groupId: Number, categoryId: Number, data: Category): Promise<ApiResponse> {
        return new ApiRequest('PUT')
            .setUrl(Api.buildApiUrl('/group/' + groupId + '/category/' + categoryId))
            .setData(data)
            .execute();
    }

    /**
     * Delete a category from a group and replace it with another one (in payments).
     *
     * @param groupId
     * @param categoryId
     * @param replacementCategoryId
     * @returns {Promise<ApiResponse>}
     */
    async deleteGroupCategory(groupId: Number, categoryId: Number, replacementCategoryId: Number): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/group/' + groupId + '/category/' + categoryId + '/delete'))
            .setData({ replacement_category_id: replacementCategoryId })
            .execute();
    }

    async getEditableUserPermissionsForUser(groupId: Number, userId: Number): Promise<ApiResponse> {
        return new ApiRequest('GET')
            .setUrl(Api.buildApiUrl('/group/' + groupId + '/members/permissions/' + userId))
            .execute();
    }

    /**
     * Get the permissions of the authenticated user for a specific group.
     *
     * @param groupId
     * @returns {Promise<ApiResponse>}
     */
    async getUserPermissions(groupId: Number): Promise<ApiResponse> {
        return new ApiRequest('GET')
            .setUrl(Api.buildApiUrl('/group/' + groupId + '/members/permissions/me'))
            .execute();
    }

    async setEditableUserPermissionsForUser(
        groupId: Number, userId: Number, groupPermissions: any
    ): Promise<ApiResponse> {
        return new ApiRequest('PUT')
            .setUrl(Api.buildApiUrl('/group/' + groupId + '/members/permissions/' + userId))
            .setData({ 'group_permissions': groupPermissions })
            .execute();
    }

    async createNewGroup(name: string, description: string): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/group'))
            .setData({ name, description })
            .execute();
    }

    async generateGroupInvitation(
        groupId: Number,
        name: string,
        usageType: string,
        invitedUserEmail: string|null,
        expiryDate: string|null,
    ): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl(`/group/${groupId}/invitations/generateToken`))
            .setData({
                name,
                usage_type: usageType,
                invited_user: invitedUserEmail,
                expires_at: expiryDate
            })
            .execute();
    }

    /**
     * Fetch all invitations for the given group.
     * 
     * @param {Number} groupId 
     */
    async getGroupInvitations(groupId: Number): Promise<ApiResponse> {
        return new ApiRequest('GET')
            .setUrl(Api.buildApiUrl(`/group/${groupId}/invitations/tokens`))
            .execute();
    }

    /**
     * Disable the given invitation (ID) of the given group (ID).
     * 
     * @param {Number} groupId 
     * @param {Number} invitationId 
     */
    async disableGroupInvitation(groupId: Number, invitationId: Number): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl(`/group/${groupId}/invitations/disableToken/${invitationId}`))
            .execute();
    }

    /**
     * Join a group with the given invitation (id and token).
     * 
     * @param {Number} invitationId id of the invitation
     * @param {String} invitationToken token of the invitation
     */
    async joinGroup(invitationId: Number, invitationToken: string): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl(`/group/join/${invitationId}`))
            .setData({ invitation_token: invitationToken })
            .execute();
    }
}