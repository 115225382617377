//
// Filters for date and time rendering.
//

const LOCALES = ['de-DE', 'en-EN'];

export function date(value: string) {
    const date = new Date(value);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(LOCALES, options);
}

export function dateTime(value: string) {
    const date = new Date(value);
    const options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString(LOCALES, options);
}

export function time(value: string) {
    const time = new Date(value);
    const options = { hour: '2-digit', minute: '2-digit' };
    return time.toLocaleTimeString(LOCALES, options);
}
