import App from './App.vue'
import AuthApi from "./library/api/Auth";
import router from './router/index';
import { store } from './store'
import Vue from 'vue'
import vuetify from "@/plugins/vuetify";

import './filters';
import Api from "./library/api/Api";

require('./registerServiceWorker');

Vue.config.productionTip = false;

// Bootstrap the App
new AuthApi().tryUserLoginWithoutCredentials().then(response => {
    if (response.isSuccess()) {
        Api.bootstrapApi(response.data);

        store.commit('setLoginWithoutCredentialsInProcess', true);
        const newUser = {
            tokenData: response.data,
            groups: []
        };
        store.commit('setUser', newUser);
        store.commit('setAccessToken', response.data.access_token);
        store.dispatch('postProcessLogin', null);
    }

    // initialize the vue app
    new Vue({
        el: '#app',
        vuetify,
        router,
        store,
        render: h => h(App)
    });
}).catch(function (response) {
    alert("There were problems reaching the API!");
});
