var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"headline"},[_vm._v(" Group Invitations ")]),_c('v-spacer'),(_vm.userPermissions.inviteUsers && open)?_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","max-width":"150px"},on:{"click":function($event){$event.stopPropagation();_vm.showNewInvitationDialog = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" Invitation ")],1):_vm._e()]}}])}),_c('v-expansion-panel-content',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.invitations,"sort-by":"created_at","sort-desc":""},scopedSlots:_vm._u([{key:"item.is_deleted",fn:function(ref){
var item = ref.item;
return [(item.is_deleted || (item.expires_at && new Date(item.expires_at) < new Date(item.created_at)))?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle-outline")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")])]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [(item.usage === 'MULTI')?_c('v-chip',{attrs:{"small":"","label":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-multiple-plus-outline")])],1),_vm._v(" M ")],1):(item.usage === 'SINGLE' && !! item.invited_user)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"small":"","label":""}},on),[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-plus")])],1),_vm._v(" U ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.invited_user.email))])]):_c('v-chip',{attrs:{"small":"","label":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-plus-outline")])],1),_vm._v(" S ")],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.created_at))+" ")]}},{key:"item.expires_at",fn:function(ref){
var item = ref.item;
return [(item.expires_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(item.expires_at)))]):_c('v-chip',{attrs:{"outlined":"","label":""}},[_vm._v("Never")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.userPermissions.inviteUsers && ! item.is_deleted)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onRemoveInvitation(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):(!! item.is_deleted)?_c('v-chip',{attrs:{"outlined":"","color":"red"}},[_vm._v(" Deleted ")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"mt-1",staticStyle:{"text-align":"right"}},[_c('v-chip',{staticClass:"mx-1",attrs:{"small":"","label":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-plus-outline")])],1),_vm._v("S")],1),_vm._v("Single-Use "),_c('v-chip',{staticClass:"mx-1",attrs:{"small":"","label":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-multiple-plus-outline")])],1),_vm._v("M")],1),_vm._v(" Multi-Use "),_c('v-chip',{staticClass:"mx-1",attrs:{"small":"","label":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-plus")])],1),_vm._v("U")],1),_vm._v(" Specific user ")],1)],1)],1)],1),_c('NewInvitationDialog',{on:{"invitation-created":_vm.loadInvitations},model:{value:(_vm.showNewInvitationDialog),callback:function ($$v) {_vm.showNewInvitationDialog=$$v},expression:"showNewInvitationDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }