




























import { Component, Prop } from 'vue-property-decorator';

const PAYMENT_AMOUNT_REGEX = /^(\d+(\.\d+)?)([-+/*]\d+(\.\d+)?)*$/;

import Vue from 'vue';

@Component
export default class AmountInput extends Vue {
  @Prop({ type: [ Number, String ], default: null }) readonly value!: number | string;
  @Prop({ type: String, default: 'Amount' }) readonly label!: string;
  @Prop({ type: Boolean, default: false }) readonly hideValidation!: boolean;

  inputValue: number = this.value as number;
  valid: boolean = true;
  hint: string = '';

  created() {
    this.$emit('validation', PAYMENT_AMOUNT_REGEX.test(this.value as string));
  }

  /** @param {String} input */
  onChange(input: string): void {
    input = input.replace(/\,/g, '.');
    if (! PAYMENT_AMOUNT_REGEX.test(input)) {
      this.valid = false;
    } else {
      const result = Math.round((eval(input) + Number.EPSILON) * 100) / 100;
      this.valid = isFinite(result);
      if (this.valid) {
        this.hint = `Result: ${result}`;
        this.$emit('input', result);
      }
    }

    this.$emit('validation', this.valid);
  }

  onCalc(): void {
    this.inputValue = this.value as number;
    this.hint = '';
  }
}
