import Api from './Api';
import ApiRequest from './http/ApiRequest';
import ApiResponse from './http/ApiResponse';

export default class UserApi extends Api {
    /**
     * Request API to register new user.
     * 
     * @param name
     * @param email
     * @param password
     * @param passwordConfirmation
     * 
     * @returns {Promise<ApiResponse>}
     */
    async register(name: string, email: string, password: string, passwordConfirmation: string): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/uac/signup'))
            .setData({
                name,
                email,
                password,
                password_confirmation: passwordConfirmation
            })
            .withoutGlobalExceptionHandling()
            .execute();
    }

    async getUserInfo(): Promise<ApiResponse> {
        return new ApiRequest('GET')
            .setUrl(Api.buildApiUrl('/uac/me'))
            .execute();
    }

    async requestNewVerificationMail(): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/uac/email/verify/resend'))
            .execute();
    }

    async changeUserEmail(email: string): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/uac/changeEmail'))
            .setData({ email })
            .execute();
    }

    async changeUserPassword(oldPassword: string, newPassword: string): Promise<ApiResponse> {
        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/uac/changePassword'))
            .setData({ oldPassword, newPassword })
            .execute();
    }

    /**
     * Build avatar url (with token) for given user id.
     *
     * @param userId
     * @returns string url to fetch avatar
     */
    getUserAvatarUrlWithApiToken(userId: Number): string {
        return this.buildApiUrlWithApiToken('/uac/user/' + userId + '/avatar');
    }

    /**
     * Get user avatar in Base64 encoded form.
     *
     * @param userId of the user who's avatar should be fetched
     * @returns {Promise<String>} base64 encoded avatar image
     */
    async getUserAvatarAsBase64(userId: Number): Promise<string | ArrayBuffer | null> {
        const xhr = new XMLHttpRequest();
        const avatarUrl = this.getUserAvatarUrlWithApiToken(userId);

        return new Promise((resolve) => {
            xhr.onload = function() {
                const reader = new FileReader();
                reader.onloadend = function() {
                    resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', avatarUrl);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }

    async uploadNewUserAvatar(avatarFile: any): Promise<ApiResponse> {
        const formData = new FormData();
        formData.append('avatar', avatarFile, avatarFile.name);

        return new ApiRequest('POST')
            .setUrl(Api.buildApiUrl('/uac/user/avatar'))
            .setData(formData)
            .execute();
    }

    async deleteUserAvatar(): Promise<ApiResponse> {
        return new ApiRequest('DELETE')
            .setUrl(Api.buildApiUrl('/uac/user/avatar'))
            .execute();
    }

    /**
     * Get user's page size
     *
     * @returns {Promise<int>}
     */
    async getUserPagesize(): Promise<Number> {
        return new Promise((resolve) => {
            let pageSize = parseInt(
                localStorage.getItem(process.env.VUE_APP_LS_KEY_PAGE_SIZE) ?? process.env.VUE_APP_DEFAULT_PAGE_SIZE
            );
            if (isNaN(pageSize)) {
                pageSize = parseInt(process.env.VUE_APP_DEFAULT_PAGE_SIZE);
            }
            resolve(pageSize);
        });
    }
}
