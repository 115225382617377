




// @ts-ignore
import marked from 'marked';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MarkdownViewer extends Vue {
  @Prop({ type: [String, Number], default: '' }) readonly markdown!: String | Number;

  get markdownCompiled(): string {
    return this.markdown ? marked(this.markdown) : '';
  }
}
