import axios from 'axios';
import moment from 'moment';
import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';

// API Imports
import Api from '@/library/api/Api';
import AuthApi from '@/library/api/Auth';
import UserApi from '@/library/api/User';
import GroupApi from '@/library/api/group/Group';
import ApiResponse from "@/library/api/http/ApiResponse";
import FilterCollection from '@/library/filter/FilterCollection';

Vue.use(Vuex);

export interface AccountrolBaseState {
    user: any,
    pagesize: Number,
    groups: { [key: number]: Group },
    accessToken?: string|null,
    selectedGroup: Number|null,
    globalMessages: any[],
    userAuthenticated: boolean,
    loginWithoutCredentialsInProcess: boolean,
}

/** Interface/ Structure of a Group. */
export interface Group {
    payments: {
        loadedPages: { [key: number]: any },
        usedPagesize: number,
        pageAmount: number,
        totalItemAmount: number,
        currentPage: number,
        filter: FilterCollection,
        lastRequestId: string|null,
        loading: boolean,
    }
}

export const store = new Vuex.Store<AccountrolBaseState>({
    state: {
        user: {
            tokenData: {},
            infos: {}
        },

        pagesize: parseInt(process.env.VUE_APP_DEFAULT_PAGE_SIZE),
        /** All groups the user is allowed to access. */
        groups: {},
        /** Bearer token for api access. */
        accessToken: null,
        /** Selected Group */
        selectedGroup: null,

        globalMessages: [],
        userAuthenticated: false,
        loginWithoutCredentialsInProcess: false
    },
    mutations: {
        setUser(state: AccountrolBaseState, payload: any): void {
            state.user = payload;
        },
        /**
         * @param state
         * @param payload new access token for api access
         */
        setAccessToken(state: AccountrolBaseState, payload: any): void {
            state.accessToken = payload;
        },
        /** Set the selected group id. */
        setSelectedGroup(state: AccountrolBaseState, groupId: number): void {
            state.selectedGroup = groupId;
        },
        setUserGroups(state: AccountrolBaseState, payload: { [key: number]: Group }): void {
            state.groups = payload;
        },
        setGroupCategories(state: AccountrolBaseState, payload: any): void {
            // create object with the following key => value mapping: categoryId => category
            let processedCategories = {};
            for (const cat of payload.categories) {
                // @ts-ignore
                processedCategories[cat.id] = cat;
            }
            Vue.set(state.groups[payload.groupId], 'categories', processedCategories);
        },
        setGroupUserPermissions(state: AccountrolBaseState, { groupId, permissions }): void {
            Vue.set(state.groups[groupId], 'userPermissions', permissions);
        },
        setGroupMembers(state: AccountrolBaseState, { groupId, members }): void {
            Vue.set(state.groups[groupId], 'members', members);
        },
        setGroupPayments(state: AccountrolBaseState, payload: any): void {
            Vue.set(state.groups[payload.groupId].payments.loadedPages, payload.page, payload.payments);
            Vue.set(state.groups[payload.groupId].payments, 'usedPagesize', payload.pagesize);
            Vue.set(state.groups[payload.groupId].payments, 'pageAmount', payload.pageAmount);
            Vue.set(state.groups[payload.groupId].payments, 'totalItemAmount', payload.totalItemAmount);
            Vue.set(state.groups[payload.groupId].payments, 'currentPage', payload.page);
        },
        setGroupPaymentsFilter(
            state: AccountrolBaseState,
            { groupId, filterCollection }: { groupId: number, filterCollection: FilterCollection }
        ): void {
            state.groups[groupId].payments.filter = filterCollection;
        },
        setGroupPaymentsLastRequestId(
          state: AccountrolBaseState,
          { groupId, requestId }: { groupId: number, requestId: string }
        ): void {
            state.groups[groupId].payments.lastRequestId = requestId;
        },
        setGroupPaymentsLoadingStatus(
          state: AccountrolBaseState,
          { groupId, loading }: { groupId: number, loading: boolean }
        ): void {
            state.groups[groupId].payments.loading = loading;
        },
        setUserInfos(state: AccountrolBaseState, userInfos: any): void {
            Vue.set(state.user, 'infos', userInfos);
            if (! state.selectedGroup && userInfos.default_group) {
                state.selectedGroup = userInfos.default_group;
            }
        },
        setUserPagesize(state: AccountrolBaseState, payload: any): void {
            state.pagesize = payload;
        },
        setUserAuthenticatedStatus(state: AccountrolBaseState, payload: any):void {
            state.userAuthenticated = payload;
        },
        setLoginWithoutCredentialsInProcess(state: AccountrolBaseState, payload: any):void {
            state.loginWithoutCredentialsInProcess = payload;
        },
        addGlobalMessage(state: AccountrolBaseState, payload: any): void {
            state.globalMessages.push({
                type: payload.type,
                message: payload.message,
                persistent: payload.persistent,
                timeout: payload.timeout,
                show: true,
                addTime: new Date()
            });
        }
    },
    actions: {
        /** User login with credentials provided with the payload. */
        doUserLogin(context, payload): Promise<void> {
            return new Promise<void>(resolve => {
                new AuthApi().userLoginWithCredentials(
                    payload.email, 
                    payload.password, 
                    payload.rememberMe
                ).then((tokenData: Object) => {
                    Api.bootstrapApi(tokenData);
                    context.dispatch('postProcessLogin', tokenData);
                    resolve();
                }).catch((error: any) => {
                    context.commit('addGlobalMessage', {
                        type: 'error',
                        message: 'Login failed! Please check your credentials.',
                        timeout: 10000,
                        persistent: false
                    });
                    resolve();
                });
            });
        },

        /**
         * Send logout-request to API, delete tokens and reload application.
         *
         * @param context store context
         * @param payload
         */
        logout(context): void {
            new AuthApi().logout().then(() => {
                context.commit('setUserAuthenticatedStatus', false);
                localStorage.removeItem(process.env.VUE_APP_LOCALSTORAGE_KEY_REMEMBER_ME_ENABLED);
                location.reload();
            });
        },

        /**
         * Post-Processing of the login.
         * Create store-user-variable, initialize values.
         *
         * @param context
         * @param payload tokenData: baerer token (& optionally rememberMe data)
         */
        async postProcessLogin(context, payload) {
            // create new user object
            if (payload !== null) {
                const newUser = {
                    tokenData: payload,
                    groups: []
                };
                context.commit('setUser', newUser);
                context.commit('setAccessToken', payload.access_token);
            }
            context.commit('setUserAuthenticatedStatus', true);

            Promise.allSettled([
                context.dispatch('loadUserInfos'),
                context.dispatch('loadUserGroups')
            ]).then(() => {
                context.commit('setLoginWithoutCredentialsInProcess', false);
            });
        },

        /**
         * Load all user-groups with group details.
         * Details include e.g. categories and permissions for the authenticated
         * user, but not any transactions.
         *
         * @param context
         */
        async loadUserGroups(context): Promise<void> {
            return new Promise((resolve, reject) => {
                axios.get(Api.buildApiUrl('/user/groups')).then((response) => {
                    let groups = response.data.data.groups;
                    // add payment object structure to each group
                    for (const groupId in groups) {
                        groups[groupId].payments = {
                            loadedPages: {},
                            usedPagesize: 0,
                            pageAmount: 1,
                            totalItemAmount: 0,
                            currentPage: 1,
                            filter: null,
                            lastRequestId: null,
                            loading: false,
                        };
                    }
                    context.commit('setUserGroups', groups);

                    const promises = [];
                    for (const groupId in groups) {
                        promises.push(context.dispatch('loadGroupDetails', groupId));
                    }

                    Promise.allSettled(promises).then(() => { resolve(); });
                }).catch(() => {
                    reject();
                });
            });
        },

        /**
         * Load general group details for given groups.
         * General details include categories and user-permissions (of the 
         * authenticated user).
         *
         * @param context
         * @param groupId
         */
        async loadGroupDetails(context, groupId: Number): Promise<void> {
            return new Promise(resolve => {
                const categoryPromise = axios.get(
                    Api.buildApiUrl('/group/' + groupId + '/categories')
                ).then((response) => {
                    context.commit('setGroupCategories', {groupId, categories: response.data.data.categories})
                });

                const permissionPromise = new GroupApi().getUserPermissions(groupId).then((response: ApiResponse) => {
                    if (! response.isSuccess()) return;
                    context.commit('setGroupUserPermissions', { groupId, permissions: response.data.group_permissions });
                });

                const memberPromise = new GroupApi().getGroupMembers(groupId).then((response: ApiResponse) => {
                    if (! response.isSuccess()) return;
                    context.commit('setGroupMembers', { groupId, members: response.data.users });
                });

                Promise.allSettled([ categoryPromise, permissionPromise, memberPromise ]).then(() => { resolve(); });
            });
        },

        /**
         * Load payments into requested group for requested page.
         * GroupId and page have to be provided with the payload.
         *
         * @param context
         * @param payload
         */
        loadPaymentsInGroup(
            context: ActionContext<AccountrolBaseState, AccountrolBaseState>,
            payload: any
        ): void {
            const groupId = payload.groupId;
            const requestId = generateRandomRequestId();

            context.commit('setGroupPaymentsLastRequestId', { groupId, requestId });
            context.commit('setGroupPaymentsLoadingStatus', { groupId, loading: true });

            const params: any = {
                page: payload.page,
                pagesize: context.state.pagesize,
            };

            // apply transaction filter collection if set and not "empty"
            const filter = context.state.groups[payload.groupId].payments.filter
            if (!! filter && filter instanceof FilterCollection && !filter.allFiltersEmpty()) {
                params.filter = JSON.stringify(filter.generateUriObject());
            }

            axios.get(
                Api.buildApiUrl('/group/' + payload.groupId + '/payments'),
                {
                    params
                }
            ).then((response) => {
                // do not commit outdated request
                if (context.state.groups[payload.groupId].payments.lastRequestId !== requestId) {
                    return;
                }

                context.commit(
                    'setGroupPayments',
                    {
                        groupId: payload.groupId,
                        page: payload.page,
                        pagesize: context.state.pagesize,
                        pageAmount: response.data.data.pagination.total_pages,
                        totalItemAmount: response.data.data.pagination.total_items,
                        payments: response.data.data.payments
                    }
                );

                context.commit('setGroupPaymentsLoadingStatus', { groupId, loading: false });
            }).catch(function(response) {
                console.log(response);
            });
        },

        /**
         * Load user data (name, email, etc.).
         *
         * @param context store context
         */
        async loadUserInfos(context): Promise<void> {
            return new Promise((resolve) => {
                // set page size
                const userApi = new UserApi();
                const pagesizePromise = userApi.getUserPagesize().then(pagesize => {
                    if (! Number.isNaN(pagesize)) {
                        context.commit('setUserPagesize', pagesize);
                    }
                });
                
                // load general user information
                const userInfoPromise = axios.get(Api.buildApiUrl('/uac/me')).then(response => {
                    context.commit('setUserInfos', response.data.data.me);
                });

                Promise.allSettled([ pagesizePromise, userInfoPromise ]).then(() => {
                    resolve();
                });
            })
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        accessToken(state) {
            return state.accessToken;
        },
        groups: (state) => {
            return state.groups;
        },
        groupById: (state) => (groupId: number) => {
            return state.groups[groupId];
        },
        isUserAuthenticated(state) {
            return state.userAuthenticated;
        },
        isLoginWithoutCredentialsInProcess(state) {
            return state.loginWithoutCredentialsInProcess;
        },
        globalMessages(state) {
            return state.globalMessages;
        }
    }
});

/** Generate a random request ID based on current time and a random string. */
export function generateRandomRequestId(): string {
    return moment().format('YYYYMMDDHHmmss_')
      + Math.random().toString(16).substr(2, 10);
}
