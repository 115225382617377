// Vue import
import Vue from 'vue';

// Filter imports
import { date, dateTime, time } from './datetimefilters';
import { formatMoney } from "./generalfilters";

// assign filters
Vue.filter('date', date);
Vue.filter('dateTime', dateTime);
Vue.filter('formatMoney', formatMoney);
Vue.filter('time', time);