



































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'DateTimeDialogSelector'
})
export default class DateTimeDialogSelector extends Vue {
  @Prop({ type: String, default: null}) readonly value!: String;
  @Prop({ type: String, default: 'Select date and time'}) readonly label!: String;

  dialog: Boolean = false;
  initDateTime: String = this.value;

  created() {
    this.$emit('input', `${this.date} ${this.time}`);
  }

  get date(): String {
    return getDate(this.value as string);
  }

  set date(date: String) {
    this.$emit('input', `${date} ${this.time}`)
  }

  get time(): String {
    return getTime(this.value as string);
  }

  set time(time: String) {
    this.$emit('input', `${this.date} ${time}`)
  }
  
  get displayDateTime(): String {
    const dateTime = new Date(`${this.date} ${this.time}`);
    return dateTime.toLocaleString();
  }

  onSubmit() {
    this.dialog = false;
  }

  onReset() {
    if (this.initDateTime) {
      this.$emit('input', this.initDateTime);
    }
  }
}

function getDate(dateTime: string|null): string {
  const date = dateTime ? new Date(dateTime) : new Date();
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0');
  var yyyy = date.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

function getTime(dateTime: string|null): string {
  const date = dateTime ? new Date(dateTime) : new Date();
  var H = String(date.getHours()).padStart(2, '0');
  var m = String(date.getMinutes()).padStart(2, '0');
  var s = String(date.getSeconds()).padStart(2, '0');
  return `${H}:${m}:${s}`;
}
