




































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import GroupApi from '@/library/api/group/Group';
import NewInvitationDialog from './NewInvitationDialog.vue';
import Vue from 'vue';

@Component({
  components: { NewInvitationDialog }
})
export default class GroupSettingsInvitationSection extends Vue {
  @Prop({ type: Object, required: true }) readonly userPermissions!: any;

  tableHeaders = [
    { text: 'Active', value: 'is_deleted', align: 'left' },
    { text: 'Name', value: 'name', align: 'left' },
    { text: 'Type', value: 'usage', align: 'left' },
    { text: 'Created', value: 'created_at', align: 'left' },
    { text: 'Expires', value: 'expires_at', align: 'left' },
    { text: 'Use-Count', value: 'use_count', align: 'right' },
    { text: 'Actions', value: 'action', align: 'right', sortable: false },
  ];
  invitations: any[] = [];
  showNewInvitationDialog: boolean = false;

  created() {
    this.loadInvitations();
  }

  get groupId() {
    return this.$store.state.selectedGroup;
  }

  @Watch('groupId')
  onGroupIdChange() {
    this.loadInvitations();
  }

  /** Load all existent invitations for the selected group. */
  loadInvitations(): void {
    new GroupApi().getGroupInvitations(this.groupId).then(response => {
      if (response.isSuccess()) {
        this.invitations = response.data.invitations;
      }
    });
  }

  /** Disable given @param {Object} invitation . */
  onRemoveInvitation(invitation: any): void {
    new GroupApi().disableGroupInvitation(this.groupId, invitation.id).then(response => {
      if (! response.isSuccess()) return;

      this.loadInvitations();
      this.$store.commit('addGlobalMessage', {
        type: 'success',
        message: 'Disabled invitation!',
        timeout: 10000,
        persistent: false
      });
    });
  }
}
