































































































import FilterGroup from '@/components/Library/Filter/FilterGroup.vue';
import UserApi from '../../library/api/User';
import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import FilterCollection from '@/library/filter/FilterCollection';
import FilterPageHeader from '@/components/Library/Filter/FilterPageHeader.vue';

const QUERY_PARAM_PAGE = 'page';
const DEFAULT_PAGE = 1;

@Component({
  name: 'Transactions',
  components: { FilterPageHeader, FilterGroup }
})
export default class Transactions extends Vue {
  newTransactionDialog: boolean = false;
  page: Number = 0;
  paginationPage: Number = 0;
  filterCollection: FilterCollection = new FilterCollection();
  filterApplied: boolean = false;

  created() {
    if (this.group.payments.filter instanceof FilterCollection) {
      this.filterCollection = this.group.payments.filter;
      this.filterApplied = !this.filterCollection.allFiltersEmpty();
    } else {
      this.filterCollection = new FilterCollection('Category', 'User', 'DateRange');
    }

    this.$store.dispatch('loadGroupDetails', this.groupId);
    // ensure page query parameter is set (at least to default value, which should be the first page)
    this.setPageToRoute(this.getPageFromRoute());
    this.changePage(this.getPageFromRoute());
  }

  get groupId(): Number {
    return this.$store.state.selectedGroup;
  }

  get group(): any {
    let group = this.$store.getters.groupById(this.groupId);
    return group ?? {};
  }

  /**
   * Return grouped payments by day. Only returns the requested page.
   * Also adds url to user's avatar for every payment.
   *
   * @returns payments grouped by day
   */
  get groupedPayments(): any[] {
    if (! this.group.payments) {
      return [];
    }

    const pages = this.group.payments.loadedPages as { [key: number]: any };
    const payments = pages[this.page as number];
    const groupedPayments = [];
    const userApi = new UserApi();

    if (! payments) {
        return [];
    }

    let lastDate = null;
    let currentDateCount = 0;
    let currentHeaderId = 0;

    const updateHeaderDayCount = (groupedPayments: any[], headerId: number, dayCount: number) => {
      const header = groupedPayments.find(pm => pm.isHeader && pm.headerId === headerId);
      header.dayCount = dayCount;
    };

    for (let payment of payments) {
      currentDateCount++;
      const currDate = new Date(payment.value_date);
      // set time to midnight for easy comparing (only day has to be compared)
      currDate.setHours(0, 0, 0, 0);

      if (lastDate === null || currDate.getTime() !== lastDate.getTime()) {
        if (currentHeaderId > 0) {
          updateHeaderDayCount(groupedPayments, currentHeaderId, currentDateCount);
        }

        currentDateCount = 0;
        currentHeaderId++;

        groupedPayments.push({
          isHeader: true,
          headerId: currentHeaderId,
          header: Vue.filter('date')(currDate),
          dayCount: 0, // will be set at next "header"-iteration
        });
      }
      payment.avatarUrl = userApi.getUserAvatarUrlWithApiToken(payment.user_id);
      groupedPayments.push(payment);

      lastDate = currDate;
    }

    // set day count for last day group
    updateHeaderDayCount(groupedPayments, currentHeaderId, currentDateCount);

    return groupedPayments;
  }

  get pagesize(): Number {
    return this.$store.state.pagesize;
  }

  get totalPageAmount(): Number {
    if (! this.group.payments) {
      return 0;
    }
    return this.group.payments.pageAmount;
  }

  get totalItemAmount(): Number {
    if (! this.group.payments) {
      return 0;
    }
    return this.group.payments.totalItemAmount;
  }

  get noData(): boolean {
    return !this.isLoading && this.totalItemAmount === 0;
  }

  get isLoading(): boolean {
      return this.group.payments.loading;
  }

  /** Amount of entries (pages) to show in the pagination component. */
  get paginationItemAmount(): Number {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 5
      case 'sm': return 6
      case 'md': return 10
      case 'lg': return 15
      case 'xl': // fall-through
      default:   return 20
    }
  }

  @Watch('$route.query.' + QUERY_PARAM_PAGE)
  onPageChange() {
    this.changePage(this.getPageFromRoute());
  }

  @Watch('group')
  onGroupChange() {
    this.$store.dispatch('loadPaymentsInGroup', {
      groupId: this.groupId,
      page: 1
    });
    this.page = 1;
    this.paginationPage = 1;
  }

  onFilterChange() {
    this.$store.commit('setGroupPaymentsFilter', {
      groupId: this.groupId,
      filterCollection: this.filterCollection
    });

    // need to reset the page, as the filter may influence the
    // amount of overall items
    this.page = 1;
    this.paginationPage = 1;
    // watcher on query page param will not trigger update, as
    // this.page === newPage for the watcher
    this.setPageToRoute(this.page);

    this.$store.dispatch('loadPaymentsInGroup', {
      groupId: this.groupId,
      page: this.page
    });
    this.filterApplied = !this.filterCollection.allFiltersEmpty();
  }

  /** Get current page from query parameter from url. */
  getPageFromRoute(): Number {
    const page = parseInt(this.$route.query[QUERY_PARAM_PAGE] as string);
    if (! isNaN(page)) {
      return page;
    }
    return DEFAULT_PAGE;
  }

  /** Set query parameter in the route to current page if not yet set. */
  setPageToRoute(newPage: Number): void {
    let page = parseInt(this.$route.query[QUERY_PARAM_PAGE] as string);
    if (isNaN(page) || page !== newPage) {
      this.$router.push({ query: { [QUERY_PARAM_PAGE]: `${newPage}` } });
    }
  }

  /**
   * Change the currently selected page.
   *
   * @param newPage
   */
  changePage(newPage: Number): void {
    // avoid duplicate store/ api request if the page has already been requested
    if (this.page === newPage) {
      return;
    }

    this.page = newPage;
    this.paginationPage = newPage;

    this.$store.dispatch('loadPaymentsInGroup', {
      groupId: this.groupId,
      page: this.page
    });
    window.scrollTo(0,0);
  }
}
