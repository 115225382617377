<template>
  <v-app dark>
    <v-app-bar
      v-if="isUserAuthenticated"
      app
      fixed
      clipped-left
      dense
    >
      <v-app-bar-nav-icon @click="$router.push('/').catch(() => {})">
        <v-img height="32" width="32" contain src="/img/icons/msapplication-icon-144x144.png" />
      </v-app-bar-nav-icon>

      <v-spacer />
      
      <v-btn
        icon
        large
      >
        <v-icon size="25px">mdi-bell-outline</v-icon>
      </v-btn>
      <GroupSwitcher />
      <ProfileMenu />
    </v-app-bar>

    <v-main v-if="loginWithoutCredentialsInProcess">
      <div class="loading-spinner">
        <v-progress-circular color="primary" size="100" width="10" indeterminate>
          <v-img height="60" contain src="/img/icons/msapplication-icon-144x144.png" />
        </v-progress-circular>
      </div>
    </v-main>
    <v-main v-else>
      <router-view />
      <BottomMainNav 
        v-if="isUserAuthenticated"
        @clickAddButton="newPaymentDialog = true"
      />
    </v-main>

    <PaymentEdit
      v-if="!! selectedGroup"
      :dialog="newPaymentDialog"
      :group-id="selectedGroup"
      create-new-payment
      @update:dialog="val => newPaymentDialog = val"
      @abort="newPaymentDialog = false"
      @saved="newPaymentDialog = false"
    />

    <!-- Global Message Snackbar -->
    <v-snackbar
      v-for="msg in globalMessages"
      :key="msg.addTime.getTime()"
      v-model="msg.show"
      :color="msg.type"
      :timeout="msg.timeout"
      multi-line
    >
      {{ msg.message }}
      <v-btn
        v-if="!msg.persistent"
        dark
        icon
        @click="msg.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  import BottomMainNav from './components/Navigation/BottomMainNav';
  import GroupSwitcher from './components/Navigation/GroupSwitcher';
  import PaymentEdit from './components/Transaction/PaymentEdit';
  import ProfileMenu from './components/Navigation/ProfileMenu';

  export default {
    components: { 
      BottomMainNav,
      GroupSwitcher,
      PaymentEdit,
      ProfileMenu
    },
    data () {
      return {
        newPaymentDialog: false,
      }
    },
    computed: {
      globalMessages() {
        return this.$store.getters.globalMessages;
      },
      selectedGroup() {
        return this.$store.state.selectedGroup;
      },
      isUserAuthenticated() {
        return this.$store.getters.isUserAuthenticated;
      },
      loginWithoutCredentialsInProcess() {
        return this.$store.getters.isLoginWithoutCredentialsInProcess;
      }
    }
  }
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background: map-get($grey, 'lighten-2');
  }
  ::-webkit-scrollbar-thumb {
    background: map-get($grey, 'darken-1');

    &:hover {
      background: map-get($blue-grey, 'lighten-2');
    }
  }

  .router-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
</style>

<style lang="scss" scoped>
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>