const VALID_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * Regular expression for a "secure" password.
 * At least one lower- and uppercase letter, one number and one special character are required.
 * Minimum length is 10 characters.
 */
const SECURE_PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$&*"§%\/()=?{}+\-_\\\[\]{}~|<>])(?=.*[0-9])(?=.*[a-z]).{10,}$/

export type ValidationResult = string | boolean;

/** Some basic rules for the vuetify form validation. */
export default {
    email: (value: any): ValidationResult => {
        return ! value || // empty is ok, should be enforced by "required" rule
            VALID_EMAIL_REGEX.test(value) || 'Invalid e-mail!';
    },
    equals: (value: any, compare: any, msg: string): ValidationResult => {
        msg = !! msg ? msg : 'No match!'
        return value === compare || msg;
    },
    minLength: (value: any, requiredLength: number): ValidationResult => {
        return value.length >= requiredLength || `At least ${requiredLength} characters required!`;
    },
    required: (value: any): ValidationResult => !!value || 'This field is required!',
    securePassword: (value: any): ValidationResult => {
        return SECURE_PASSWORD_REGEX.test(value) || 'This password is too weak!';
    }
}