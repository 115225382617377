













import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class GroupAdvancedMenu extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value!: boolean;
}
