

















































































































import Component from 'vue-class-component';
import { ROUTE_LOGIN } from '@/router';
import RULES from '@/library/validation/rules';
import UserApi from '@/library/api/User';
import Vue from 'vue';

const ERROR_TRANSLATION_MAPPING: { [key: string]: string } = {
  EMAIL_ALREADY_IN_USE: 'An account has already been registered using this email address.'
}

@Component({
  name: 'Register',
  filters: {
    transError(err: string): string {
      if (Object.keys(ERROR_TRANSLATION_MAPPING).includes(err)) {
        return ERROR_TRANSLATION_MAPPING[err];
      }
      return err;
    }
  }
})
export default class Register extends Vue {
  name: string = '';
  email: string = '';
  password: string = '';
  passwordConfirmation: string = '';

  rules = RULES;
  valid: boolean = false;

  criteriaInfoDialog: boolean = false;

  registrationInProcess: boolean = false;
  errors: any[] = [];

  doRegistration(): void {
    this.registrationInProcess = true;
    new UserApi().register(this.name, this.email, this.password, this.passwordConfirmation).then(response => {
      this.registrationInProcess = false;
      if (! response.isSuccess()) {
        this.errors = response.data.validation_errors_merged;
      } else {
        this.errors = [];
        this.$store.commit('addGlobalMessage', {
          type: 'success',
          message: 'Account successfully created, you can login now...',
          timeout: 10000,
          persistent: false
        });
        this.$router.push({ name: ROUTE_LOGIN });
      }
    })
  }
}
