



































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'Select'
})
export default class Select extends Vue {
  @Prop({ type: [Number, String, Array], default: null }) readonly value!: Number | String | Array<any> | null;
  @Prop() readonly items!: Array<any>;
  @Prop({ default: 'text' }) readonly itemText!: String;
  @Prop({ default: 'value' }) readonly itemValue!: String;
  @Prop({ default: 'Select' }) readonly label!: String;
  @Prop({ type: Boolean, default: false }) readonly multiple!: Boolean;
  @Prop({ type: Boolean, default: false }) readonly hideValidation!: Boolean;
  @Prop({ type: Boolean, default: false }) readonly clearable!: Boolean;
  @Prop({ default: null }) readonly prependInnerIcon?: String;

  created(): void {
    this.$emit('validation', this.validSelection);
  }

  get selectedItems(): Number | String | Array<any> | null {
    return this.value;
  }

  set selectedItems(value: Number | String | Array<any> | null) {
    this.$emit('input', value);
  }

  get toggleIcon(): String {
    if (! this.multiple || ! (this.selectedItems instanceof Array)) { return ''; }
    if (! this.selectedItems) { return 'mdi-checkbox-blank-outline'; }

    if (this.selectedItems.length === this.items.length) {
      return 'mdi-checkbox-marked'
    } else if (this.selectedItems.length > 0 && this.selectedItems.length < this.items.length) {
      return 'mdi-minus-box';
    } else {
      return 'mdi-checkbox-blank-outline';
    }
  }

  get toggleColor(): String {
    if (
      ! this.multiple || ! this.selectedItems ||
      ! (this.selectedItems instanceof Array)
    ) {
      return '';
    }
    return this.selectedItems.length > 0 ? 'primary' : '';
  }

  get validSelection(): Boolean {
    if (! this.multiple || ! (this.selectedItems instanceof Array)) { 
      return !! this.selectedItems;
    }
    
    return this.selectedItems && this.selectedItems.length > 0;
  }

  @Watch('validSelection')
  onValidSelectionChange(valid: Boolean): void {
    this.$emit('validation', valid)
  }

  toggleAllItems(): void {
    if (! this.multiple || ! (this.selectedItems instanceof Array)) {
      return;
    }

    this.$nextTick(() => {
      if (
          this.selectedItems && this.selectedItems instanceof Array &&
          this.selectedItems.length === this.items.length
      ) {
        this.selectedItems = [];
      } else {
        const allItems = this.items.map(item => item[`${this.itemValue}`]);
        this.selectedItems = allItems;
      }
    })
  }
}
