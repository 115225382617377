

























import FilterGroup from './FilterGroup.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FilterCollection, { JsonFilter } from '../../../library/filter/FilterCollection';

@Component({
  components: { FilterGroup }
})
export default class FilterPageHeader extends Vue {
  @Prop({ type: FilterCollection, required: true }) value!: FilterCollection;
  @Prop({ type: String, required: true }) pageTitle!: string;
  @Prop({ type: Number, required: true }) groupId!: number;

  isFilterApplied: boolean = !this.value.allFiltersEmpty();

  get filterCollection(): JsonFilter[] {
    return this.value.toJsonFilterArray();
  }

  set filterCollection(newFilterCollection: JsonFilter[]) {
    const collection = FilterCollection.fromJsonFilterArray(newFilterCollection);
    this.isFilterApplied = !collection.allFiltersEmpty();
    this.$emit('input', collection);
    this.$emit('filter-changed');
  }
}
