import Api from '../Api'
import ApiRequest from '../http/ApiRequest';
import ApiResponse from '../http/ApiResponse';
import FilterCollection from '@/library/filter/FilterCollection';

export default class GroupStatisticsApi extends Api {
    /**
     * Get current balance of the group according to given filters.
     *
     * @param groupId Id of the group to get the balance overview for.
     * @param filters Filter Collection to load Balance Overview with.
     * 
     * @returns { Promise<ApiResponse> }
     */
    async getGroupBalance(groupId: Number, filters: FilterCollection): Promise<ApiResponse> {
        const params: any = {};
        if (!filters.allFiltersEmpty()) {
            params.filter = JSON.stringify(filters.generateUriObject());
        }

        return new ApiRequest('GET')
            .setQueryParameters(params)
            .setUrl(Api.buildApiUrl(`/group/${groupId}/stats/balance`))
            .execute();
    }

    /**
     * Get spending for group grouped by given group (category or user) and
     * according to given filters.
     *
     * @param groupId
     * @param group e.g. 'user_id', or 'category_id'
     * @param filters
     */
    async getGroupGroupedSpending(
      groupId: number,
      group: string = 'category_id',
      filters: FilterCollection|null = null,
    ): Promise<ApiResponse> {
        const params: any = { group };
        if (filters instanceof FilterCollection && !filters.allFiltersEmpty()) {
            params.filter = JSON.stringify(filters.generateUriObject());
        }

        return new ApiRequest('GET')
            .setQueryParameters(params)
            .setUrl(Api.buildApiUrl(`/group/${groupId}/stats/groupedSpending`))
            .execute();
    }
}
