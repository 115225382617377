

















































import Component from 'vue-class-component';
import GroupApi from '@/library/api/group/Group';
import Vue from 'vue';

@Component({
  inject: ['theme']
})
export default class RemoveCategory extends Vue {
  groupApi: GroupApi = new GroupApi();
  groupId!: number;
  dialog: boolean = false;
  deleting: boolean = false;

  categoryToDelete = {
    name: '',
    id: 0
  };
  replacementCategoryId: number|null = null;
  groupCategories: any[] = [];

  get replacementCategories(): any[] {
    let cats: any[] = [];
    for (let category of this.groupCategories) {
      if (category.id !== this.categoryToDelete.id) {
        cats.push(category);
      }
    }
    return cats;
  }

  get isFormReady(): boolean {
    return this.replacementCategoryId !== null;
  }

  /**
   * Start adding or editing category
   *
   * @param groupId
   * @param category the category object of the category, that should be deleted
   */
  initialize(groupId: number, category: any): void {
    const me = this;

    this.deleting = false;
    this.dialog = true;
    this.groupId = groupId;
    this.replacementCategoryId = null;
    this.categoryToDelete = category;

    this.groupApi.getGroupCategories(this.groupId).then((response) => {
      me.groupCategories = response.data.categories;
    });
  }

  /** Delete the category */
  onDelete(): void {
    this.deleting = true;

    this.groupApi.deleteGroupCategory(
        this.groupId,
        this.categoryToDelete.id,
        this.replacementCategoryId as number
    ).then((res) => {
      let message = '';
      if (res.isSuccess()) {
        message = 'Category successfully deleted!'
      } else {
        message = 'Category could not be deleted!'
      }
      this.$store.commit('addGlobalMessage', {
        type: res.isSuccess() ? 'success' : 'error',
        message: message,
        timeout: 5000,
        persistent: false
      });
      this.deleting = false;
      this.dialog = false;
      this.$emit('categories-changed');
    });
  }
}
